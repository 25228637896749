import axios from 'axios';
import { CreateEmployeeBodyAndResponse } from '../schemas/PositionEmployee.schema';
import { ResponseList } from 'models/common/respone.model';

export function putPositionEmployee(
    username: string,
    app_id: string,
    positionEmployeeList: CreateEmployeeBodyAndResponse[]
) {
    const body = {
        username: username,
        position_employee_list: positionEmployeeList
    };

    return axios.post<ResponseList<CreateEmployeeBodyAndResponse>>(
        `/v2/configuration/position-employee?app_id=${app_id}`,
        body,
        {
            headers: {
                Accept: 'application/json',
                'Content-Type': 'application/json',
                Authorization:
                    'Bearer ' + localStorage.getItem('WF_APPLICATION')
            }
        }
    );
}

export function putUpdatePositionEmployee(
    positionEmployeeList: CreateEmployeeBodyAndResponse[]
) {
    const body = {
        position_employee_list: positionEmployeeList
    };

    return axios.post<string>(
        `/v2/update-configuration/position-employee`,
        body,
        {
            headers: {
                Accept: 'application/json',
                'Content-Type': 'application/json',
                Authorization:
                    'Bearer ' + localStorage.getItem('WF_APPLICATION')
            }
        }
    );
}
