import { WorkflowInbox } from "models/workflow/WorkflowInbox";
import { WorkflowInboxParams } from "../schemas/Workflow.shema";
import { DatasourceReturnType, ResponseError } from "models/common/respone.model";
import { retrieveWorkflowInbox } from "../datasource/Workflow.datasource";
import { ResponseList } from "models/Response";

export async function getWorkflowInbox(
    params: WorkflowInboxParams
): Promise<DatasourceReturnType<ResponseError, ResponseList<WorkflowInbox>>> {
    try {
        const data = (await retrieveWorkflowInbox(params)).data;

        return [null!, data];
    } catch (error) {
        throw error;
    }
}