import * as React from 'react';
import QueryString from 'query-string';
import { Redirect } from 'react-router';
import axios, { AxiosError } from 'axios';
import { extractAccessToken } from 'helpers/decodeLocalStorage';

type State = {
    isLogged: 'pending' | 'success' | 'failed';
    loggingError?: string;
};

class Ingress extends React.Component<any, State> {
    constructor(props: any) {
        super(props);

        this.state = {
            isLogged: 'pending'
        };
    }

    componentDidMount() {
        window.history.pushState({}, 'Ingress', '/');
        //console.log("/Ingress")
        //const { wfId, docNo, wfInstanceNo, userId } = this.props.match.params
        const { token, redirect } = QueryString.parse(
            this.props.location.search
        ) as Record<string, string>;

        var jwt = this.parseJwt(token);
        var extra = JSON.parse(jwt.extra);
        var data = {
            access_token: token
            /*            user_id: extra.user_id*/
        };

        localStorage.removeItem('WF_APPLICATION');
        localStorage.setItem('WF_APPLICATION', JSON.stringify(data));

        const citizenId = extractAccessToken().citizen_id;
        this.logAuthenticationHistory(citizenId, () => {
            if (!redirect) {
                return;
            }

            console.log(redirect);

            const encode = atob(redirect);
            return (window.top!.location.href = encode);
        });
    }

    parseJwt(token) {
        var base64Url = token.split('.')[1];
        var base64 = base64Url.replace(/-/g, '+').replace(/_/g, '/');
        var jsonPayload = decodeURIComponent(
            atob(base64)
                .split('')
                .map(function (c) {
                    return (
                        '%' + ('00' + c.charCodeAt(0).toString(16)).slice(-2)
                    );
                })
                .join('')
        );

        return JSON.parse(jsonPayload);
    }

    logAuthenticationHistory = async (
        citizenId: string,
        onSuccess?: () => void
    ) => {
        try {
            this.setState({ isLogged: 'pending' });

            const body = {
                citizen_id: citizenId,
                mode: localStorage.getItem('mode') || 'web'
            };

            await axios.post('/v1/log-auth-history', body, {
                headers: {
                    Authorization:
                        'Bearer ' + localStorage.getItem('WF_APPLICATION')
                }
            });

            this.setState({ isLogged: 'success' });
            onSuccess?.();
        } catch (error) {
            console.error('log-auth-history', error);

            if (error instanceof AxiosError) {
                console.error(
                    'logging authentication error:',
                    error.response?.data
                );
                this.setState({
                    isLogged: 'failed',
                    loggingError: JSON.stringify(error.response?.data)
                });
            } else if (error instanceof Error) {
                console.error('logging authentication error:', error.message);
                this.setState({
                    isLogged: 'failed',
                    loggingError: error.message
                });
            }
        }
    };

    componentDidUpdate() {}

    render() {
        const { isLogged, loggingError } = this.state;

        if (isLogged === 'pending') return null;

        if (isLogged === 'failed') {
            return (
                <div>
                    <p className="font-bold">Failed to logged authentication</p>
                    <p>Error: {loggingError}</p>
                </div>
            );
        }

        return <Redirect to="/" />;
    }
}

export default Ingress;
