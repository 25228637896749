import axios from "axios";

import { AssignmentRoutingMemberParams, AssignmentRoutingMemberResponse, AssignmentRoutingParams, AssignmentRoutingResponse } from "../schemas/AssignmentRouting.schema";
import { ResponseList } from "models/Response";

export function retrieveWorkflowAssignment(params: AssignmentRoutingParams) {
    const searchSids = new URLSearchParams(params.sids.map(sid => ['sid', sid] as [string, string])).toString();

    const { sids, ...optionalParams } = params;
    const searchOptional = new URLSearchParams(optionalParams as any).toString();

    const queryString = searchSids + '&' + searchOptional;

    return axios.get<ResponseList<AssignmentRoutingResponse>>(`/v1/workflow-assignment?${queryString}`, {
        headers: {
            'Authorization': 'Bearer ' + localStorage.getItem('WF_APPLICATION')
        }
    })
}

export function retrieveWorkflowAssignmentMember(params: AssignmentRoutingMemberParams) {
    return axios.get<AssignmentRoutingMemberResponse[]>(`/v1/workflow-assignment-member`, {
        params,
        headers: {
            'Authorization': 'Bearer ' + localStorage.getItem('WF_APPLICATION')
        }
    })
}