import * as React from 'react';
import { Container } from 'reactstrap';
import { Route, Switch } from 'react-router';
import NavMenu from './NavMenu';
import SubLayout from './SubLayout';

import Home from './Home';
import SignInComponent from './auth/SignInComponent';
import UserEmployee from './useremployee/UserEmployee'
import PositionEmployee from './positionemployee/PositionEmployee';
import AddPositionEmployee from './positionemployee/AddPositionEmployee'
import PositionGroup from './positiongroup/PositionGroup'
import AddPositionGroup from './positiongroup/AddPositionGroup'
import Workflow from './workflow/Workflow'
import AddWorkflow from './workflow/AddWorkflow'
import SignOut from './auth/SignOutComponent'
import ConditionMaster from './condition/ConditionMaster'
import TypeMaster from './type/TypeMaster'
import WorkflowInbox from './workflow/WorkflowInbox'
import WorkflowDashboard from './workflow/WorkflowDashboard'
import AddConfigMaster from './config/AddConfigMaster'
import WorkflowDashboardInformationDetail from './workflow/WorkflowDashboardInformationDetail'
import CreateWorkflow from './workflow/CreateWorkflow'
import WorkflowInboxInformation from './workflow/WorkflowInboxInformation'
import WorkflowInformation from './workflow/WorkflowInformation'
import Ingress from './ingress/Ingress'
import WorkflowReportAdmin from './workflow/WorkflowReportAdmin'
import WorkflowLog from './log/Log'
import EmailLog from './log/EmailLog';
import CreateWorkflowWithWftype from './workflow/CreateWorkflowWithWftype'
import MasterEmployee from './config/MasterEmployee'
import WorkflowInboxOverview from './workflow/WorkflowInboxOverview'
import WorkflowDashboardOverview from './workflow/WorkflowDashboardOverview'
import { PrivateRoute } from './PrivateRoute';
import WorkflowInboxMobile from './workflow/WorkflowInboxMobile';
import WorkflowConditionConfigReport from './workflowreport/WorkflowConditionConfigurationReport';
import WorkflowAssignmentRoutingReport from './workflowreport/WorkflowAssignmentRoutingReport';
import SimulateWorkflow from './simulateworkflow/SimulateWorkflow';
import EsignCallback from './esigncallback/esigncallback';
import WorkflowOnlineForm from './workflow/WorkflowOnlineForm';
import OnlineFormInformation from './workflow/OnlineFormInformation';
import LineConnectView from '../presentations/views/LineConnect/LineConnect.view';
import WorkflowOnlineFromView from 'presentations/views/WorkflowOnlineForm/WorkflowOnlineForm.view';
import WorkflowConfigAdmin from 'presentations/views/WorkflowConfigAdmin/WorkflowConfigAdmin.view';
import RoleMasterEmployee from 'presentations/views/RoleMasterEmployee/RoleMasterEmployee.view';
import LogBuffer from 'presentations/views/LogBuffer/WorkflowLogBuffer.view';
import CallbackLogStart from 'presentations/views/CallbackStart/CallbackStart.view';
import CallbackLog from 'presentations/views/CallbackLog/CallbackLog.view';
import BufferStat from 'presentations/views/BufferStat/BufferStat.view';
import AssignmentRoutingConfig from 'presentations/views/AssignmentRouting/AssignmentRoutingConfig.view';
import AuthenticationHistoryLogs from './history/AuthenticationHistoryLogs';

export default (props: { children?: React.ReactNode }) => (
    <React.Fragment>
        {/* <NavMenu/>
        <Container>
            {props.children}
        </Container> */}
        <Switch>
            <Route path='/auth/signin' component={SignInComponent} />
            <Route path='/workflow-report' component={WorkflowDashboard} />
            {/*<Route path='/workflow-inbox-detail' component={WorkflowInformation} />*/}
            <Route path='/workflow-create' component={CreateWorkflow} />
            <Route path='/ingress-workflow-inbox' component={Ingress} />
            <Route path='/callback/e-sign' component={EsignCallback} />
            <Route path='/workflow-online-form' component={WorkflowOnlineFromView} />
            <Route path='/online-form-information' component={OnlineFormInformation} />
            <Route path='/line-connect' component={LineConnectView} />
            <Route path='/workflow-report-detail' component={WorkflowDashboardInformationDetail} />
            {/* <SubLayout>
                <PrivateRoute exact path='/' component={Home} />
                <PrivateRoute path='/function' component={Function} />
                <PrivateRoute path='/logout' component={SignOut} />
            </SubLayout> */}
            {/* <NavMenu /> */}
            <SubLayout>
                <PrivateRoute exact path='/' component={Home} />
                <Route path='/workflowinbox-mobile' component={WorkflowInboxMobile} />
                <Route path='/workflow-inbox-overview' component={WorkflowInboxOverview} />
                <Route path='/workflow-inbox-detail' component={WorkflowInboxInformation} />
                <Route path='/workflow-report-overview' component={WorkflowDashboardOverview} />
                {/* <PrivateRoute path='/useremployee' component={UserEmployee} /> */}
                <PrivateRoute path='/roleemployee' component={PositionEmployee} />
                <PrivateRoute path='/addpositionemployee' component={AddPositionEmployee} />
                <PrivateRoute path='/authoritygroup' component={PositionGroup} />
                <PrivateRoute path='/addauthoritygroup' component={AddPositionGroup} />
                <PrivateRoute path='/workflow' component={Workflow} />
                <PrivateRoute path='/addworkflow' component={AddWorkflow} />
                <PrivateRoute path='/logout' component={SignOut} />
                {/*<PrivateRoute path='/auth/signin' component={SignInComponent} />*/}
                <PrivateRoute path='/conditionmaster' component={ConditionMaster} />
                <PrivateRoute path='/workflowreport' component={WorkflowReportAdmin} />
                <PrivateRoute path='/workflowlog' component={WorkflowLog} />
                <PrivateRoute path='/workflow-email-log' component={EmailLog} />
                {/* <PrivateRoute path='/typemaster' component={TypeMaster} /> */}
                <PrivateRoute path='/workflowinbox' component={WorkflowInbox} />
                {/*<PrivateRoute path='/workflowinbox-mobile' component={WorkflowInboxMobile} />*/}
                <PrivateRoute path='/workflowdashboard' component={WorkflowDashboard} />
                <PrivateRoute path='/callbackconfigmaster' component={AddConfigMaster} />
                <PrivateRoute path='/createworkflow' component={CreateWorkflow} />
                <PrivateRoute path='/createworkflowwithwftype' component={CreateWorkflowWithWftype} />
                <PrivateRoute path='/masterEmployee' component={MasterEmployee} />
                <PrivateRoute path='/workflowinboxadmin' component={WorkflowInbox} />
                <PrivateRoute path='/workflowinboxadminsummary' component={WorkflowReportAdmin} />
                <PrivateRoute path='/workflowconditionconfigurationreport' component={WorkflowConditionConfigReport} />
                <PrivateRoute path='/workflowassignmentroutingreport' component={WorkflowAssignmentRoutingReport} />
                <PrivateRoute path='/simulateworkflow' component={SimulateWorkflow} />
                <PrivateRoute path='/createUserAdmin' component={WorkflowConfigAdmin} />
                <PrivateRoute path='/rolemasteremployee' component={RoleMasterEmployee} />
                <PrivateRoute path='/workflowlogbuffer' component={LogBuffer} />
                <PrivateRoute path='/callbacklogstart' component={CallbackLogStart} />
                <PrivateRoute path='/callbacklog' component={CallbackLog} />
                <PrivateRoute path='/bufferstat' component={BufferStat} />
                <PrivateRoute path='/assignmentroutingconfig' component={AssignmentRoutingConfig} />
                <PrivateRoute path='/auth-history' component={AuthenticationHistoryLogs} />
            </SubLayout>
        </Switch>
    </React.Fragment>
);
