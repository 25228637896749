import * as React from 'react';
import { connect } from 'react-redux';
import {
    CardBody,
    Container,
    Modal,
    ModalBody,
    ModalFooter,
    ModalHeader
} from 'reactstrap';
import { Button, Card, Col, Form, Row, Table } from 'react-bootstrap';
import {
    FaTimes,
    FaClock,
    FaCheck,
    FaFileAlt,
    FaShare,
    FaTimesCircle,
    FaCheckCircle
} from 'react-icons/fa';
import { ApplicationState } from '../../store';
import Swal from 'sweetalert2';
import path from 'path';

//import Loader from 'react-loader'
import * as SearchWorkflowDashboardStore from '../../store/workflow/SearchWorkflowDashboard';
import * as ModelSearchWorkflowDashboard from '../../models/workflow/SearchWorkflowInbox';
import { parseJwt, onClickFileShow } from '../../modules/Common';
import QueryString from 'query-string';
//import * as ModelApproveRejectWorkflow from '../../models/workflow/ApproveRejectWorkflow';

import * as ApproveRejectWorkflowInformationStore from '../../store/workflow/ApproveRejectWorkflowInformation';
import * as ModelApproveRejectWorkflowInformation from '../../models/workflow/ApproveRejectWorkflowInformation';

import * as Models from '../../models/reference/Reference';

import {
    numberWithCommas,
    onClickFileShowIframe,
    formatYMDToDMY,
    formatDMY
} from '../../modules/Common';
import * as ModelsWorkflowInbox from '../../models/workflow/WorkflowInbox';
import { debounce, safeParseJson } from '../../helpers/utils';
import BorderGrid from '../../common/BorderGrid';
import { ViewportContext } from '../../context/ViewportProvider';
import WorkflowInboxInformationMobileTable from '../mobiletable/WorkflowInboxInformationMobileTable';
import { SpinnerLoader } from '../../helpers/dom-utils';
import { twMerge } from 'tailwind-merge';
import { FaListUl, FaTableCells } from 'react-icons/fa6';
import ApprovalInChargeStepper from 'common/components/ApprovalInChargeStepper';

var Loader = require('react-loader');

type Store = ModelSearchWorkflowDashboard.GetWorkflowInboxValueState &
    typeof SearchWorkflowDashboardStore.actionCreators;

class WorkflowInboxInformationDetail extends React.Component<
    any,
    {
        fileName: any;
        src: any;
        mode: any;
        checkwfid: boolean;
        formData: Array<ModelSearchWorkflowDashboard.ResponseWorkflowInboxValue>;
        checkresponse: boolean;
        checkapprove: boolean;
        checkreject: boolean;
        token: string;
        workflowValueUse: ModelsWorkflowInbox.WorkflowValue;
        successModal: boolean;
        controlRedirect: boolean;
        checkurl: any;
        successModalHeader: string;
        showModalUrl: boolean;
        messageModal: boolean;
        modeApproveReject: string;
        message: string;
        docNoList: any;
        srcHistory: any;
        canReject: boolean;
        innerWidth: number;
        presentMode: string;
        displayApproveInChargeMode: 'step' | 'table';
    },
    Store
> {
    public static contextType = ViewportContext;
    private isAlreadyDefaultClickFile = false;

    constructor(props: any) {
        super(props);
        this.state = {
            checkwfid: true,
            checkresponse: true,
            formData: [],
            checkapprove: true,
            checkreject: true,
            src: undefined,
            token: '',
            fileName: undefined,
            mode: undefined,
            successModal: false,
            controlRedirect: false,
            checkurl: '',
            successModalHeader: '',
            showModalUrl: true,
            messageModal: false,
            modeApproveReject: '',
            message: '',
            docNoList: [],
            srcHistory: '',
            workflowValueUse: {
                wfId: '',
                docNo: '',
                wfInstanceNo: '',
                userId: '',
                token: '',
                sid: '',
                appId: ''
            },
            canReject: false,
            innerWidth: window.innerWidth,
            presentMode: '',
            displayApproveInChargeMode: 'table'
        };
    }

    componentDidMount() {
        //this.state.srcHistory = window.document.location.href
        this.setState({
            srcHistory: window.document.location.href
        });
        //window.history.pushState({}, "Workflow Information", "/workflow-information");

        const { showmodal } = this.props;
        if (this.props.location != undefined) {
            // window.history.pushState({}, "Workflow Information", "/workflow-information");

            var {
                wfId,
                docNo,
                wfInstanceNo,
                userId,
                token,
                ref_id,
                app_id,
                can_reject,
                presentMode
            } = QueryString.parse(this.props.location.search) as Record<
                string,
                string
            >;

            if (ref_id) {
                this.props.history.push(
                    '/workflow-report-overview?ref_id=' + ref_id
                );
                //fetch('/workflow/reference?id=' + ref_id, {
                //    method: 'GET',
                //})
                //    .then((response) => {
                //        if (response.status != 200) {
                //            throw new Error(response.json() as any)
                //        } else {
                //            return response.json() as Promise<Models.Response>
                //        }
                //    })
                //    .then(data => {
                //        console.log(data)
                //        let params = new URLSearchParams(data.parameter);
                //        wfId = params.get("wfId");
                //        docNo = params.get("docNo");
                //        wfInstanceNo = params.get("wfInstanceNo");
                //        userId = params.get("userId");
                //        token = params.get("token");
                //        if (params.get("app_id") != null || params.get("app_id") != undefined) {
                //            app_id = params.get("app_id");
                //        } else {
                //            app_id = data.sid;
                //        }

                //        console.log(app_id)
                //        this.props.requestsearchworkflowDashboard(true, "POST", wfId, docNo, userId, wfInstanceNo, token, app_id)

                //        this.setState({
                //            token: token,
                //            checkwfid: false,
                //        })
                //    })
                //    .catch(error => {
                //        console.log(error)
                //    });
            } else {
                setTimeout(() => SpinnerLoader.hide(), 0);

                if (app_id) {
                    this.props.requestsearchworkflowDashboard(
                        true,
                        'POST',
                        wfId,
                        docNo,
                        userId,
                        wfInstanceNo,
                        token,
                        app_id
                    );
                } else {
                    var jToken = parseJwt(token);
                    app_id = jToken.app;
                    this.props.requestsearchworkflowDashboard(
                        true,
                        'POST',
                        wfId,
                        docNo,
                        userId,
                        wfInstanceNo,
                        token,
                        app_id
                    );
                }

                var canReject = false;
                if (can_reject == 'true') {
                    canReject = true;
                }

                var access_token = parseJwt(token);
                // var extra = JSON.parse(access_token.extra);
                const extra = safeParseJson(access_token) ?? userId;

                var user_id = userId;
                if (user_id) {
                    if (extra.citizen_id) {
                        user_id = extra.citizen_id;
                    } else if (extra.username) {
                        user_id = extra.username;
                    }
                }

                this.setState({
                    workflowValueUse: {
                        wfId: wfId,
                        docNo: docNo,
                        wfInstanceNo: wfInstanceNo,
                        userId: user_id,
                        token: token,
                        sid: app_id,
                        appId: app_id
                    },
                    token: token,
                    checkwfid: false,
                    canReject: canReject,
                    presentMode
                });
            }
        } else {
            const { workflowValue } = this.props;
            this.setState({
                workflowValueUse: {
                    wfId: workflowValue.wfId,
                    docNo: workflowValue.docNo,
                    wfInstanceNo: workflowValue.wfInstanceNo,
                    userId: workflowValue.userId,
                    token: workflowValue.token,
                    sid: workflowValue.appId,
                    appId: workflowValue.appId
                },
                checkurl: 'No_URL',
                showModalUrl: showmodal
            });
        }

        //window.history.pushState({}, "Workflow Information", "/workflow-information");
        ////window.history.pushState({}, "Workflow Information", "/workflowdashboard");
        ////console.log("/workflowinformation")
        ////const { wfId, docNo, wfInstanceNo, userId } = this.props.match.params
        //const { wfId, docNo, wfInstanceNo, userId, token } = QueryString.parse(this.props.location.search);
        //this.props.requestsearchworkflowDashboard(true, "POST", wfId, docNo, userId, wfInstanceNo, token)
        ////document.getElementsByClassName('Loader__background')[0].className = "Loader__background show";
        //this.setState({
        //    checkwfid: false,
        //})

        //window.addEventListener("message", (event) => {
        //    if (event.data != undefined) {
        //        const obj = JSON.parse(event.data)
        //        switch (obj.key) {
        //            case 'on_success_approve_reject':
        //                this.setState({
        //                    message: obj.value
        //                })

        //                this.onsuccessApproveReject()

        //                break;
        //            case 'handle_message':
        //                this.handleMessage()

        //                // code block
        //                break;
        //            case 'handle_success_modal':

        //                // code block
        //                break;
        //            default:
        //            // code block
        //        }

        //    }
        //}, false);

        const onResizeDebounce = debounce(this.onResize, 200);
        window.addEventListener('resize', onResizeDebounce);
    }

    onResize = (_event: UIEvent) => {
        this.setState({ innerWidth: window.innerWidth });
    };

    componentDidUpdate() {
        const {
            workflowValueUse,
            statusResponse,
            isLoadApproveReject,
            messageResponseApproveReject,
            statusResponseApproveReject
        } = this.props;
        const { wfId, docNo, wfInstanceNo, userId } = QueryString.parse(
            this.props.location.search
        );
        if (this.state.checkwfid) {
            this.setState({
                checkwfid: false
            });
        } else if (
            statusResponse == 200 &&
            (this.state.checkapprove || this.state.checkreject)
        ) {
            // document.getElementsByClassName('Loader__background')[0].className = "Loader__background hide";
            this.props.requestsearchworkflowDashboard(
                true,
                'CLEAR',
                wfId,
                docNo,
                userId,
                wfInstanceNo
            );
        }

        const {
            statusWorkflowInboxValue,
            responseWorkflowInboxValue,
            isLoadWorkflowInboxValue
        } = this.props;
        if (
            statusWorkflowInboxValue == 200 &&
            responseWorkflowInboxValue != undefined &&
            this.state.checkresponse
        ) {
            setTimeout(() => {
                this.postContentHeight();
            }, 1000);
            this.setState({
                checkresponse: false,
                formData: responseWorkflowInboxValue
            });

            window.top?.postMessage(
                JSON.stringify({
                    key: 'wf_type',
                    value: responseWorkflowInboxValue['docType']
                }),
                '*'
            );
        }

        //if (isLoadApproveReject && statusResponseApproveReject == 200 && (this.state.checkapprove || this.state.checkreject)) {
        //    console.log('WFINFO APPROVE SUCCESS')
        //    //document.getElementsByClassName('Loader__background')[0].className = "Loader__background hide";
        //    this.hideLoading()
        //    this.setState({
        //        checkresponse: true,
        //        checkapprove: false,
        //        checkreject: false,
        //        formData: [],
        //        messageModal: false,
        //        modeApproveReject: "",
        //        message: "",
        //        successModal: true
        //    })
        //    //setTimeout(() => {
        //    //    Swal.fire({
        //    //        title: 'สำเร็จ!',
        //    //        text: 'กดปุ่มยืนยัน',
        //    //        icon: 'success',
        //    //        showCancelButton: false,
        //    //        confirmButtonColor: '#3085d6',
        //    //        confirmButtonText: 'ยืนยัน!',
        //    //        allowOutsideClick: false,
        //    //    }).then((result) => {
        //    //        if (result.value) {
        //    //            if (this.state.checkurl == "No_URL") {
        //    //                this.props.blindopenModal("reload")
        //    //            } else {
        //    //                this.setState({
        //    //                    controlRedirect: true
        //    //                })
        //    //            }
        //    //            this.setState({
        //    //                checkapprove: true,
        //    //            })
        //    //            this.props.requestsearchworkflowInbox(true, "POST", workflowValueUse.wfId, workflowValueUse.docNo, workflowValueUse.userId, workflowValueUse.wfInstanceNo)

        //    //            //document.getElementsByClassName('Loader__background')[0].className = "Loader__background show";
        //    //        }
        //    //    })
        //    //}, 500);
        //} else if (isLoadApproveReject && statusResponseApproveReject == 400 && (this.state.checkapprove || this.state.checkreject)) {
        //    var msg = JSON.parse(messageResponseApproveReject)
        //    //document.getElementsByClassName('Loader__background')[0].className = "Loader__background hide";
        //    this.hideLoading()
        //    this.setState({
        //        checkresponse: true,
        //        checkapprove: false,
        //        checkreject: false,
        //        formData: [],
        //        messageModal: false,
        //        modeApproveReject: "",
        //        message: ""
        //    })

        //    this.props.requestsearchworkflowDashboard(true, "CLEAR", wfId, docNo, userId, wfInstanceNo)
        //    this.props.requestApproveWorkflow(true, "CLEAR", "", "", "");
        //    this.props.requestsearchworkflowDashboard(true, "POST", workflowValueUse.wfId, workflowValueUse.docNo, workflowValueUse.userId, workflowValueUse.wfInstanceNo, workflowValueUse.token, workflowValueUse.appId)
        //    setTimeout(() => {
        //        Swal.fire({
        //            title: 'ไม่สำเร็จ!',
        //            text: msg.message,
        //            icon: 'error',
        //            showCancelButton: false,
        //            confirmButtonColor: '#3085d6',
        //            confirmButtonText: 'ตกลง',
        //            allowOutsideClick: false,
        //        }).then((result) => {
        //            if (result.value) {
        //                if (this.state.checkurl == "No_URL") {
        //                    //this.props.blindopenModal("reload")
        //                }
        //                this.setState({
        //                    checkapprove: true,
        //                })
        //                //document.getElementsByClassName('Loader__background')[0].className = "Loader__background show";
        //            }
        //        })
        //    }, 500);
        //}
    }

    postContentHeight() {
        var elmnt = document.getElementById('content') as HTMLBodyElement;

        //window.top.postMessage(elmnt.offsetHeight, '*');
        //var height = (document.getElementById("content") as any).clientHeight;;

        // Append dispatch event
        // window.top?.postMessage(
        //     JSON.stringify({
        //         key: 'height',
        //         value: elmnt.offsetHeight ?? 0
        //     }),
        //     '*'
        // );
    }

    showLoading() {
        //window.top.postMessage(
        //    JSON.stringify({
        //        key: 'loading_show',
        //        value: ''
        //    }),
        //    '*'
        //);
    }

    showMessageModal() {
        //window.top.postMessage(
        //    JSON.stringify({
        //        key: 'message_modal_show',
        //        value: 'reject'
        //    }),
        //    '*'
        //);
    }

    showSuccessModal() {
        //window.top.postMessage(
        //    JSON.stringify({
        //        key: 'success_modal_show',
        //        value: ''
        //    }),
        //    '*'
        //);
    }

    hideLoading() {
        //window.top.postMessage(
        //    JSON.stringify({
        //        key: 'loading_hide',
        //        value: ''
        //    }),
        //    '*'
        //);
    }

    onClickFileShowIframe = (e: any, fileName: any, mode: any) => {
        onClickFileShowIframe(e, mode, this.state.token).then(src => {
            setTimeout(() => {
                this.postContentHeight();
            }, 1000);
            this.setState({
                src: src,
                fileName: fileName,
                mode: mode
            });
        });
    };

    handleCloseFile = () => {
        setTimeout(() => {
            this.postContentHeight();
        }, 100);
        this.setState({
            src: undefined,
            fileName: undefined
        });
    };

    onClickFileShowFile = (e: any, fileName: any, filepath: any, mode: any) => {
        e.target.dataset.filepath = filepath;
        e.target.dataset.filename = fileName;
        e.target.dataset.mode = mode;
        //console.log(e.target.dataset.filepath)
        //console.log(e.target.dataset.filename)
        onClickFileShow(e, this.state.token);
        //.then(src => {
        //    console.log("111111111111111111111")
        //    console.log(src)
        //    //window.open(src);
        //});
    };

    handleSuccessModal = () => {
        const { workflowValueUse } = this.state;
        if (this.state.successModal) {
            this.props.requestApproveWorkflowInformation(
                true,
                'CLEAR',
                workflowValueUse.userId,
                workflowValueUse.docNo,
                ''
            );
            this.props.requestsearchworkflowDashboard(
                true,
                'CLEAR',
                workflowValueUse.wfId,
                workflowValueUse.docNo,
                workflowValueUse.userId,
                workflowValueUse.wfInstanceNo
            );
            //this.props.requestsearchworkflowDashboard(true, "POST", workflowValueUse.wfId, workflowValueUse.docNo, workflowValueUse.userId, workflowValueUse.wfInstanceNo, workflowValueUse.token, workflowValueUse.appId)
            //this.props.requestsearchworkflowInbox(true, "CLEAR", workflowValueUse.wfId, workflowValueUse.docNo, workflowValueUse.userId, workflowValueUse.wfInstanceNo)
            //this.props.refreshPageChild()
            if (this.state.checkurl == 'No_URL') {
                this.props.blindopenModal('reload');
            } else {
                this.setState({
                    controlRedirect: true
                });
            }
            this.setState({
                successModal: false,
                checkapprove: true,
                successModalHeader: ''
            });
        } else {
            this.setState({
                successModal: true,
                successModalHeader: ''
            });
        }
    };

    onsuccessApproveReject = () => {
        //document.getElementsByClassName('Loader__background')[0].className = "Loader__background show";
        this.showLoading();

        var messagePush: any = [];
        messagePush = this.state.docNoList;
        for (let i = 0; i < messagePush.length; i++) {
            messagePush[i]['app_id'] = this.state.workflowValueUse.appId;
            messagePush[i]['user_id'] = this.state.workflowValueUse.userId;
            messagePush[i]['wf_type'] = this.state.workflowValueUse.wfType;
            messagePush[i]['message'] = this.state.message;
            messagePush[i]['instance_no_list'] = [
                this.state.workflowValueUse.wfInstanceNo
            ];
        }
        this.setState({
            docNoList: messagePush
        });

        if (this.state.modeApproveReject == 'approved') {
            //this.props.requestApproveWorkflow(true, "PUT", userId, docNo, this.state.message, wfType);
            this.props.requestApproveWorkflowList(
                true,
                'PUT',
                this.state.docNoList
            );
        } else {
            //this.props.requestRejectWorkflow(true, "PUT", userId, docNo, this.state.message, wfType);
            this.props.requestRejectWorkflowList(
                true,
                'PUT',
                this.state.docNoList
            );
        }
    };

    handleMessage = () => {
        if (this.state.messageModal) {
            this.setState({
                messageModal: false,
                modeApproveReject: '',
                message: '',
                docNoList: []
            });
        } else {
            this.setState({
                messageModal: true
            });
        }
    };

    onchangeMessage = (e: { target: { name: any; value: any } }) => {
        this.setState({
            message: e.target.value
        });
        setTimeout(() => {
            console.log(this.state.message);
        }, 500);
    };

    handleApprove = (
        wfInstanceNo: string,
        docNo: string,
        userId: string,
        wfType: string,
        sid: string
    ) => {
        var wfUse: any = [];
        var wfins = {
            app_id: sid,
            user_id: userId,
            wf_type: wfType,
            doc_no_list: [docNo],
            instance_no_list: [wfInstanceNo]
        };
        wfUse.push(wfins);
        this.setState({
            docNoList: wfUse,
            successModalHeader: 'Approved Status'
        });
        //document.getElementsByClassName('Loader__background')[0].className = "Loader__background show";
        this.showLoading();
        setTimeout(() => {
            //document.getElementsByClassName('Loader__background')[0].className = "Loader__background hide";
            this.hideLoading();
            this.setState({
                messageModal: true,
                modeApproveReject: 'approved'
            });
        }, 500);

        //this.props.requestApproveWorkflow(true, "PUT", userId, docNo, "", wfType);
    };

    handleReject = (
        wfInstanceNo: string,
        docNo: string,
        userId: string,
        wfType: string,
        sid: string
    ) => {
        var wfUse: any = [];
        var wfins = {
            app_id: sid,
            user_id: userId,
            wf_type: wfType,
            doc_no_list: [docNo],
            instance_no_list: [wfInstanceNo]
        };
        wfUse.push(wfins);
        this.setState({
            docNoList: wfUse,
            successModalHeader: 'Rejected Status'
        });
        //document.getElementsByClassName('Loader__background')[0].className = "Loader__background show";
        this.showLoading();
        setTimeout(() => {
            //document.getElementsByClassName('Loader__background')[0].className = "Loader__background hide";
            this.hideLoading();
            this.setState({
                messageModal: true,
                modeApproveReject: 'reject'
            });
            this.showMessageModal();
        }, 500);
        //this.props.requestRejectWorkflow(true, "PUT", userId, docNo, "", wfType);
    };

    render() {
        const {
            statusWorkflowInboxValue,
            responseWorkflowInboxValue,
            isLoadWorkflowInboxValue
        } = this.props;
        const formData = this.state
            .formData as unknown as ModelSearchWorkflowDashboard.ResponseWorkflowInboxValue;
        const [docDate, docTime] = formData.createdOnFormatDateTime?.split(
            ' '
        ) ?? ['', ''];

        if (
            statusWorkflowInboxValue == 204 &&
            isLoadWorkflowInboxValue &&
            this.state.checkresponse
        ) {
            Swal.fire({
                title: 'ไม่สำเร็จ!',
                text: 'ไม่พบข้อมูล',
                icon: 'error',
                showCancelButton: false,
                confirmButtonColor: '#3085d6',
                confirmButtonText: 'ยืนยัน!'
            }).then(result => {
                if (result.value) {
                }
            });
        }

        const tableFooter = {
            width: '100%',
            backgroundColor: '#1473E6',
            color: '#FFFFFF'
        };

        console.log(responseWorkflowInboxValue);

        const { width } = this.context;
        const smBreakpoint = 640;
        const breakpoint = 768; // Table breakpoint

        switch (this.state.presentMode) {
            case 'information': {
                return (
                    <>
                        <div className="grid grid-cols-4 gap-4">
                            <div className="col-span-4 space-y-2">
                                <p className="font-semibold">Subject</p>
                                <p className="rounded-md bg-blue-100 px-4 py-2 drop-shadow-md">
                                    {formData.subject}
                                </p>
                            </div>
                            <div className="col-span-4 space-y-2">
                                <p className="font-semibold">Online Form ID</p>
                                <p className="rounded-md bg-blue-100 px-4 py-2 drop-shadow-md">
                                    {formData.docNo}
                                </p>
                            </div>
                            <div className="col-span-4 space-y-2">
                                <p className="font-semibold">Requestor</p>
                                <p className="rounded-md bg-blue-100 px-4 py-2 drop-shadow-md">
                                    {formData.requestorName}
                                </p>
                            </div>
                            <div className="col-span-2 space-y-2">
                                <p className="font-semibold">Document Date</p>
                                <p className="rounded-md bg-blue-100 px-4 py-2 drop-shadow-md">
                                    {!this.state.checkresponse
                                        ? (docDate as any).replaceAll('.', '/')
                                        : ''}
                                </p>
                            </div>
                            <div className="col-span-2 space-y-2">
                                <p className="font-semibold">Document Time</p>
                                <p className="rounded-md bg-blue-100 px-4 py-2 drop-shadow-md">
                                    {!this.state.checkresponse
                                        ? `${docTime} น.`
                                        : ''}
                                </p>
                            </div>
                            <div className="col-span-2 flex items-center gap-4">
                                <p className="font-semibold">
                                    Approve in Charge
                                </p>
                                <div className="flex w-fit divide-x overflow-hidden rounded-md border">
                                    <button
                                        onClick={() =>
                                            this.setState({
                                                displayApproveInChargeMode:
                                                    'table'
                                            })
                                        }
                                        className={twMerge(
                                            'flex items-center justify-center px-2 py-1.5 transition-colors',
                                            this.state
                                                .displayApproveInChargeMode ===
                                                'table' &&
                                                'bg-[#3085d6] text-white'
                                        )}
                                    >
                                        <FaTableCells />
                                    </button>
                                    <button
                                        onClick={() =>
                                            this.setState({
                                                displayApproveInChargeMode:
                                                    'step'
                                            })
                                        }
                                        className={twMerge(
                                            'flex items-center justify-center px-2 py-1.5 transition-colors',
                                            this.state
                                                .displayApproveInChargeMode ===
                                                'step' &&
                                                'bg-[#3085d6] text-white'
                                        )}
                                    >
                                        <FaListUl />
                                    </button>
                                </div>
                            </div>
                        </div>
                        {innerWidth > breakpoint ? (
                            <>
                                {this.state.displayApproveInChargeMode ===
                                'step' ? (
                                    <ApprovalInChargeStepper
                                        document={
                                            this.state
                                                .formData as unknown as ModelSearchWorkflowDashboard.ResponseWorkflowInboxValue
                                        }
                                    />
                                ) : (
                                    <Table
                                        responsive
                                        hover
                                        size="lg"
                                        className="table-bordered"
                                    >
                                        <thead>
                                            <tr
                                                className="table-active text-center"
                                                style={tableFooter}
                                            >
                                                <th
                                                    style={{ width: '2%' }}
                                                ></th>
                                                <th style={{ width: '20%' }}>
                                                    Approver Code
                                                </th>
                                                <th style={{ width: '20%' }}>
                                                    Approver Name
                                                </th>
                                                <th style={{ width: '10%' }}>
                                                    สถานะ
                                                </th>
                                                <th style={{ width: '28%' }}>
                                                    Description
                                                </th>
                                                <th style={{ width: '25%' }}>
                                                    Approve Date/Time
                                                </th>
                                            </tr>
                                        </thead>
                                        {!this.state.checkresponse &&
                                            this.state.formData[
                                                'listApprovalDetail'
                                            ].map(
                                                (
                                                    item: ModelSearchWorkflowDashboard.WorkflowInboxValue,
                                                    idx
                                                ) => (
                                                    <tbody key={idx}>
                                                        <tr
                                                            key={'1-' + idx}
                                                            style={
                                                                item.status ==
                                                                'APPROVED'
                                                                    ? {
                                                                          backgroundColor:
                                                                              '#90be6d',
                                                                          width: '100%'
                                                                      }
                                                                    : item.status ==
                                                                      'CANCEL'
                                                                    ? {
                                                                          backgroundColor:
                                                                              '#e5383b',
                                                                          width: '100%'
                                                                      }
                                                                    : {
                                                                          backgroundColor:
                                                                              '#f8f9fa',
                                                                          width: '100%'
                                                                      }
                                                            }
                                                        >
                                                            <td
                                                                style={{
                                                                    width: '2%'
                                                                }}
                                                            >
                                                                {idx + 1}
                                                            </td>
                                                            <td
                                                                style={{
                                                                    width: '20%'
                                                                }}
                                                            >
                                                                {
                                                                    item.positiongroupname
                                                                }
                                                            </td>
                                                            <td
                                                                style={{
                                                                    width: '20%'
                                                                }}
                                                            >
                                                                {item.status ==
                                                                    'APPROVED' ||
                                                                item.status ==
                                                                    'CANCEL'
                                                                    ? item.updated_byname
                                                                    : ''}
                                                            </td>
                                                            <td
                                                                style={{
                                                                    width: '10%'
                                                                }}
                                                                className="text-center"
                                                            >
                                                                {item.status ==
                                                                'APPROVED' ? (
                                                                    <FaCheck />
                                                                ) : item.status ==
                                                                  'CANCEL' ? (
                                                                    <FaTimes />
                                                                ) : (
                                                                    <FaClock />
                                                                )}
                                                            </td>
                                                            <td
                                                                style={{
                                                                    width: '28%'
                                                                }}
                                                            >
                                                                {item.status ==
                                                                    'APPROVED' ||
                                                                item.status ==
                                                                    'CANCEL'
                                                                    ? item.reason
                                                                    : ''}
                                                            </td>
                                                            <td
                                                                style={{
                                                                    width: '25%'
                                                                }}
                                                            >
                                                                {item.status ==
                                                                    'APPROVED' ||
                                                                item.status ==
                                                                    'CANCEL'
                                                                    ? formatDMY(
                                                                          String(
                                                                              item.updatedOnFormatDateTime
                                                                          )
                                                                      )
                                                                    : ''}
                                                            </td>
                                                        </tr>
                                                        <tr key={'2-' + idx}>
                                                            <td></td>
                                                            <td colSpan={5}>
                                                                <table
                                                                    style={{
                                                                        width: '100%'
                                                                    }}
                                                                    className="table-borderless"
                                                                >
                                                                    {!this.state
                                                                        .checkresponse
                                                                        ? this.state.formData[
                                                                              'listApprovalDetail'
                                                                          ][
                                                                              idx
                                                                          ][
                                                                              'listEmployeeInPositionGroup'
                                                                          ].map(
                                                                              (
                                                                                  subitem: ModelSearchWorkflowDashboard.listEmployeeInPositionGroup,
                                                                                  subidx
                                                                              ) => (
                                                                                  <tbody
                                                                                      key={
                                                                                          subidx
                                                                                      }
                                                                                  >
                                                                                      <tr
                                                                                          key={
                                                                                              subidx
                                                                                          }
                                                                                          style={{
                                                                                              width: '100%'
                                                                                          }}
                                                                                      >
                                                                                          <td
                                                                                              style={{
                                                                                                  width: '20%'
                                                                                              }}
                                                                                          >
                                                                                              {
                                                                                                  subitem.empid
                                                                                              }
                                                                                          </td>
                                                                                          <td
                                                                                              style={{
                                                                                                  width: '20%'
                                                                                              }}
                                                                                          >
                                                                                              {
                                                                                                  subitem.empname
                                                                                              }
                                                                                          </td>
                                                                                          <td
                                                                                              className="text-center"
                                                                                              style={{
                                                                                                  width: '10%'
                                                                                              }}
                                                                                          ></td>
                                                                                          <td
                                                                                              style={{
                                                                                                  width: '30%'
                                                                                              }}
                                                                                          ></td>
                                                                                          <td
                                                                                              style={{
                                                                                                  width: '25%'
                                                                                              }}
                                                                                          ></td>
                                                                                      </tr>
                                                                                  </tbody>
                                                                              )
                                                                          )
                                                                        : null}
                                                                </table>
                                                            </td>
                                                        </tr>
                                                    </tbody>
                                                )
                                            )}
                                    </Table>
                                )}
                            </>
                        ) : (
                            <>
                                {this.state.displayApproveInChargeMode ===
                                'step' ? (
                                    <ApprovalInChargeStepper
                                        document={
                                            this.state
                                                .formData as unknown as ModelSearchWorkflowDashboard.ResponseWorkflowInboxValue
                                        }
                                    />
                                ) : (
                                    <WorkflowInboxInformationMobileTable
                                        checkresponse={this.state.checkresponse}
                                        formData={this.state.formData}
                                    />
                                )}
                            </>
                        )}
                    </>
                );
            }
            case 'document': {
                return (
                    <>
                        <div className="grid grid-cols-4 gap-4">
                            <div className="col-span-4 space-y-2">
                                <p className="font-semibold">
                                    Document Online Form
                                </p>
                                <p className="rounded-md bg-blue-100 px-4 py-2 drop-shadow-md">
                                    {JSON.parse(
                                        (this.state.formData as any)
                                            .listSignFileUrl ||
                                            (this.state.formData as any)
                                                .listFileUrl ||
                                            '[]'
                                    ).map(
                                        (
                                            file: ModelSearchWorkflowDashboard.FileUrl,
                                            index: number
                                        ) => (
                                            <React.Fragment key={file.url}>
                                                {file.file_name && file.url && (
                                                    <>
                                                        <span
                                                            data-filename={
                                                                file.file_name
                                                            }
                                                            data-filepath={
                                                                file.url
                                                            }
                                                            onClick={event =>
                                                                this.onClickFileShowFile(
                                                                    event,
                                                                    file.file_name,
                                                                    file.url,
                                                                    's3'
                                                                )
                                                            }
                                                            className="cursor-pointer"
                                                        >
                                                            <FaShare
                                                                style={{
                                                                    transform:
                                                                        'rotate(270deg)',
                                                                    color: 'green'
                                                                }}
                                                            />
                                                        </span>{' '}
                                                        <span
                                                            ref={ref => {
                                                                !this
                                                                    .isAlreadyDefaultClickFile &&
                                                                    index ===
                                                                        0 &&
                                                                    ref?.click();
                                                                this.isAlreadyDefaultClickFile =
                                                                    true;
                                                            }}
                                                            data-filename={
                                                                file.file_name
                                                            }
                                                            data-filepath={
                                                                file.url
                                                            }
                                                            onClick={event =>
                                                                this.onClickFileShowIframe(
                                                                    event,
                                                                    file.file_name,
                                                                    's3'
                                                                )
                                                            }
                                                            className={`cursor-pointer text-[#0366d6] ${
                                                                this.state
                                                                    .fileName ===
                                                                file.file_name
                                                                    ? 'underline'
                                                                    : ''
                                                            }`}
                                                        >
                                                            <FaFileAlt />{' '}
                                                            {file.file_name}
                                                        </span>
                                                    </>
                                                )}
                                            </React.Fragment>
                                        )
                                    )}
                                </p>
                            </div>
                        </div>
                        {this.state.src != undefined && (
                            <Row className="mt-3">
                                <Col>
                                    <Card>
                                        <Card.Header>
                                            {this.state.fileName}
                                            <span style={{ float: 'right' }}>
                                                <Button
                                                    size="sm"
                                                    variant="outline-danger"
                                                    onClick={
                                                        this.handleCloseFile
                                                    }
                                                >
                                                    <FaTimes
                                                        size={15}
                                                        color="red"
                                                    />
                                                    &nbsp;ปิด
                                                </Button>
                                            </span>
                                        </Card.Header>
                                        <Card.Body>
                                            <iframe
                                                style={{ minHeight: '1025px' }}
                                                src={this.state.src}
                                                height="100%"
                                                width="100%"
                                                frameBorder="0"
                                            />
                                        </Card.Body>
                                    </Card>
                                </Col>
                            </Row>
                        )}
                    </>
                );
            }
            default: {
                // Mobile
                if (innerWidth < smBreakpoint) {
                    return (
                        <Container
                            fluid={true}
                            className="mt-3"
                        >
                            <Loader loaded={isLoadWorkflowInboxValue}>
                                <Row className="mt-3">
                                    <Col>
                                        <Card>
                                            <Card.Body>
                                                <BorderGrid>
                                                    <BorderGrid.Row>
                                                        <BorderGrid.Col>
                                                            <div>
                                                                <label>
                                                                    Subject
                                                                </label>
                                                            </div>
                                                            <div>
                                                                <b>
                                                                    {
                                                                        this
                                                                            .state
                                                                            .formData[
                                                                            'subject'
                                                                        ]
                                                                    }
                                                                </b>
                                                            </div>
                                                        </BorderGrid.Col>
                                                        <BorderGrid.Col>
                                                            <div>
                                                                <label>
                                                                    Document
                                                                    Type
                                                                </label>
                                                            </div>
                                                            <div>
                                                                <b>
                                                                    {
                                                                        this
                                                                            .state
                                                                            .formData[
                                                                            'docType'
                                                                        ]
                                                                    }
                                                                </b>
                                                            </div>
                                                        </BorderGrid.Col>
                                                    </BorderGrid.Row>

                                                    <BorderGrid.Row>
                                                        <BorderGrid.Col>
                                                            <div>
                                                                <label>
                                                                    Document No.
                                                                </label>
                                                            </div>
                                                            <div>
                                                                <b>
                                                                    {
                                                                        this
                                                                            .state
                                                                            .formData[
                                                                            'docNo'
                                                                        ]
                                                                    }
                                                                </b>
                                                            </div>
                                                        </BorderGrid.Col>
                                                        {!this.state.formData[
                                                            'jobName'
                                                        ] && (
                                                            <BorderGrid.Col>
                                                                <div>
                                                                    <label>
                                                                        Project
                                                                        Name
                                                                    </label>
                                                                </div>
                                                                <div>
                                                                    <b>
                                                                        {
                                                                            this
                                                                                .state
                                                                                .formData[
                                                                                'projectName'
                                                                            ]
                                                                        }
                                                                    </b>
                                                                </div>
                                                            </BorderGrid.Col>
                                                        )}
                                                        {this.state.formData[
                                                            'jobName'
                                                        ] && (
                                                            <BorderGrid.Col>
                                                                <div>
                                                                    <label>
                                                                        Job Name
                                                                    </label>
                                                                </div>
                                                                <div>
                                                                    <b>
                                                                        {
                                                                            this
                                                                                .state
                                                                                .formData[
                                                                                'jobName'
                                                                            ]
                                                                        }
                                                                    </b>
                                                                </div>
                                                            </BorderGrid.Col>
                                                        )}
                                                    </BorderGrid.Row>

                                                    <BorderGrid.Row>
                                                        <BorderGrid.Col>
                                                            <div>
                                                                <label>
                                                                    Year
                                                                </label>
                                                            </div>
                                                            <div>
                                                                <b>
                                                                    {
                                                                        this
                                                                            .state
                                                                            .formData[
                                                                            'docYear'
                                                                        ]
                                                                    }
                                                                </b>
                                                            </div>
                                                        </BorderGrid.Col>
                                                        <BorderGrid.Col>
                                                            <div>
                                                                <label>
                                                                    Document
                                                                    Date
                                                                </label>
                                                            </div>
                                                            <div>
                                                                <b>
                                                                    {!this.state
                                                                        .checkresponse
                                                                        ? formatYMDToDMY(
                                                                              String(
                                                                                  this
                                                                                      .state
                                                                                      .formData[
                                                                                      'docDate'
                                                                                  ]
                                                                              )
                                                                          )
                                                                        : ''}
                                                                </b>
                                                            </div>
                                                        </BorderGrid.Col>
                                                    </BorderGrid.Row>

                                                    <BorderGrid.Row>
                                                        <BorderGrid.Col>
                                                            <div>
                                                                <label>
                                                                    Requestor
                                                                </label>
                                                            </div>
                                                            <div>
                                                                <b>
                                                                    {
                                                                        this
                                                                            .state
                                                                            .formData[
                                                                            'requestorName'
                                                                        ]
                                                                    }
                                                                </b>
                                                            </div>
                                                        </BorderGrid.Col>
                                                        <BorderGrid.Col>
                                                            <div>
                                                                <label>
                                                                    Requestor
                                                                    Amount
                                                                </label>
                                                            </div>
                                                            <div>
                                                                <b>
                                                                    {numberWithCommas(
                                                                        this
                                                                            .state
                                                                            .formData[
                                                                            'docAmount'
                                                                        ]
                                                                    )}
                                                                    &nbsp;
                                                                    {
                                                                        this
                                                                            .state
                                                                            .formData[
                                                                            'docCurrency'
                                                                        ]
                                                                    }
                                                                </b>
                                                            </div>
                                                        </BorderGrid.Col>
                                                    </BorderGrid.Row>

                                                    <BorderGrid.Row>
                                                        <BorderGrid.Col>
                                                            <div>
                                                                <label>
                                                                    Current
                                                                    approval
                                                                </label>
                                                            </div>
                                                            <div>
                                                                <b>
                                                                    {this.state
                                                                        .formData[
                                                                        'curtaskid'
                                                                    ] +
                                                                        '/' +
                                                                        this
                                                                            .state
                                                                            .formData[
                                                                            'maxtaskid'
                                                                        ]}
                                                                </b>
                                                            </div>
                                                        </BorderGrid.Col>
                                                        <BorderGrid.Col>
                                                            <div>
                                                                <label>
                                                                    Message from
                                                                    requested
                                                                </label>
                                                            </div>
                                                            <div>
                                                                <b>
                                                                    {
                                                                        this
                                                                            .state
                                                                            .formData[
                                                                            'requestorMessage'
                                                                        ]
                                                                    }
                                                                </b>
                                                            </div>
                                                        </BorderGrid.Col>
                                                    </BorderGrid.Row>

                                                    <BorderGrid.Row>
                                                        <BorderGrid.Col>
                                                            <div>
                                                                <label>
                                                                    Current
                                                                    approval
                                                                </label>
                                                            </div>
                                                            <div>
                                                                <b>
                                                                    {this.state
                                                                        .formData[
                                                                        'curtaskid'
                                                                    ] +
                                                                        '/' +
                                                                        this
                                                                            .state
                                                                            .formData[
                                                                            'maxtaskid'
                                                                        ]}
                                                                </b>
                                                            </div>
                                                        </BorderGrid.Col>
                                                        <BorderGrid.Col>
                                                            <div>
                                                                <label>
                                                                    Message from
                                                                    requested
                                                                </label>
                                                            </div>
                                                            <div>
                                                                <b>
                                                                    {
                                                                        this
                                                                            .state
                                                                            .formData[
                                                                            'requestorMessage'
                                                                        ]
                                                                    }
                                                                </b>
                                                            </div>
                                                        </BorderGrid.Col>
                                                    </BorderGrid.Row>

                                                    {/* <BorderGrid.Row>
                                                <BorderGrid.Col>
                                                    <div>
                                                        <label>Document Online Form</label>
                                                    </div>
                                                    <div>
                                                        {(this.state.formData['listSignFileUrl'] === "") && (this.state.formData['listFileUrl'])
                                                            ? JSON.parse(this.state.formData['listFileUrl'])
                                                                .map((item: ModelSearchWorkflowDashboard.FileUrl, index) =>
                                                                ((item.file_name != undefined || item.url != undefined) &&
                                                                    <div key={index}>
                                                                        <b>
                                                                            <a
                                                                                key={item.file_name + '_FileAlt'}
                                                                                href="javascript:void(0);"
                                                                                data-filename={item.file_name}
                                                                                data-filepath={item.url}
                                                                                onClick={(e) => this.onClickFileShowIframe(e, item.file_name, 's3')}>
                                                                                <FaFileAlt />&nbsp;{item.file_name}
                                                                            </a>
                                                                        </b>
                                                                    </div>
                                                                )
                                                                )
                                                            : (this.state.formData['listSignFileUrl']) ? JSON.parse(this.state.formData['listSignFileUrl']).map((item: ModelSearchWorkflowDashboard.FileUrl, index) =>
                                                            ((item.file_name != undefined || item.url != undefined) &&
                                                                <div key={index}>
                                                                    <b>
                                                                        <a
                                                                            key={item.file_name + '_FileAlt'}
                                                                            href="javascript:void(0);"
                                                                            data-filename={item.file_name}
                                                                            data-filepath={item.url}
                                                                            onClick={(e) => this.onClickFileShowIframe(e, item.file_name, 's3')}>
                                                                            <FaFileAlt />&nbsp;{item.file_name}
                                                                        </a>
                                                                    </b>
                                                                </div>
                                                            )) : null}
                                                    </div>
                                                </BorderGrid.Col>
                                            </BorderGrid.Row>

                                            <BorderGrid.Row>
                                                <BorderGrid.Col>
                                                    <div>
                                                        <label>Document Attachment</label>
                                                    </div>
                                                    <div>
                                                        {(this.state.formData['listFileUrl'])
                                                            ? JSON.parse(this.state.formData['listFileUrl'])
                                                                .map((item: ModelSearchWorkflowDashboard.File, index) =>
                                                                ((item.file_name != undefined || item.file_path != undefined) &&
                                                                    <div key={index}>
                                                                        <b>
                                                                            <a
                                                                                key={item.file_name + '_FileAlt'}
                                                                                href="javascript:void(0);"
                                                                                data-filename={item.file_name}
                                                                                data-filepath={item.file_path}
                                                                                onClick={(e) => this.onClickFileShowIframe(e, item.file_name, undefined)}>
                                                                                <FaFileAlt />&nbsp;{item.file_name}
                                                                            </a>
                                                                        </b>
                                                                    </div>
                                                                )
                                                                )
                                                            : null}
                                                    </div>
                                                </BorderGrid.Col>
                                            </BorderGrid.Row> */}
                                                </BorderGrid>
                                            </Card.Body>
                                        </Card>
                                    </Col>
                                </Row>
                                {this.state.src != undefined && (
                                    <Row className="mt-3">
                                        <Col>
                                            <Card>
                                                <Card.Header>
                                                    {this.state.fileName}
                                                    <span
                                                        style={{
                                                            float: 'right'
                                                        }}
                                                    >
                                                        <Button
                                                            size="sm"
                                                            variant="outline-danger"
                                                            onClick={
                                                                this
                                                                    .handleCloseFile
                                                            }
                                                        >
                                                            <FaTimes
                                                                size={15}
                                                                color="red"
                                                            />
                                                            &nbsp;ปิด
                                                        </Button>
                                                    </span>
                                                </Card.Header>
                                                <Card.Body>
                                                    <iframe
                                                        style={{
                                                            minHeight: '1025px'
                                                        }}
                                                        src={this.state.src}
                                                        height="100%"
                                                        width="100%"
                                                        frameBorder="0"
                                                    />
                                                </Card.Body>
                                            </Card>
                                        </Col>
                                    </Row>
                                )}
                                <Row className="mt-3">
                                    <Col>
                                        {width > breakpoint ? (
                                            <Table
                                                responsive
                                                hover
                                                size="lg"
                                                className="table-bordered"
                                            >
                                                <thead>
                                                    <tr
                                                        className="table-active text-center"
                                                        style={tableFooter}
                                                    >
                                                        <th
                                                            style={{
                                                                width: '2%'
                                                            }}
                                                        ></th>
                                                        <th
                                                            style={{
                                                                width: '20%'
                                                            }}
                                                        >
                                                            Approver Code
                                                        </th>
                                                        <th
                                                            style={{
                                                                width: '20%'
                                                            }}
                                                        >
                                                            Approver Name
                                                        </th>
                                                        <th
                                                            style={{
                                                                width: '10%'
                                                            }}
                                                        >
                                                            สถานะ
                                                        </th>
                                                        <th
                                                            style={{
                                                                width: '28%'
                                                            }}
                                                        >
                                                            Description
                                                        </th>
                                                        <th
                                                            style={{
                                                                width: '25%'
                                                            }}
                                                        >
                                                            Approve Date/Time
                                                        </th>
                                                    </tr>
                                                </thead>
                                                {!this.state.checkresponse &&
                                                    this.state.formData[
                                                        'listApprovalDetail'
                                                    ].map(
                                                        (
                                                            item: ModelSearchWorkflowDashboard.WorkflowInboxValue,
                                                            idx
                                                        ) => (
                                                            <tbody key={idx}>
                                                                <tr
                                                                    key={
                                                                        '1-' +
                                                                        idx
                                                                    }
                                                                    style={
                                                                        item.status ==
                                                                        'APPROVED'
                                                                            ? {
                                                                                  backgroundColor:
                                                                                      '#90be6d',
                                                                                  width: '100%'
                                                                              }
                                                                            : item.status ==
                                                                              'CANCEL'
                                                                            ? {
                                                                                  backgroundColor:
                                                                                      '#e5383b',
                                                                                  width: '100%'
                                                                              }
                                                                            : {
                                                                                  backgroundColor:
                                                                                      '#f8f9fa',
                                                                                  width: '100%'
                                                                              }
                                                                    }
                                                                >
                                                                    <td
                                                                        style={{
                                                                            width: '2%'
                                                                        }}
                                                                    >
                                                                        {idx +
                                                                            1}
                                                                    </td>
                                                                    <td
                                                                        style={{
                                                                            width: '20%'
                                                                        }}
                                                                    >
                                                                        {
                                                                            item.positiongroupname
                                                                        }
                                                                    </td>
                                                                    <td
                                                                        style={{
                                                                            width: '20%'
                                                                        }}
                                                                    >
                                                                        {item.status ==
                                                                            'APPROVED' ||
                                                                        item.status ==
                                                                            'CANCEL'
                                                                            ? item.updated_byname
                                                                            : ''}
                                                                    </td>
                                                                    <td
                                                                        style={{
                                                                            width: '10%'
                                                                        }}
                                                                        className="text-center"
                                                                    >
                                                                        {item.status ==
                                                                        'APPROVED' ? (
                                                                            <FaCheck />
                                                                        ) : item.status ==
                                                                          'CANCEL' ? (
                                                                            <FaTimes />
                                                                        ) : (
                                                                            <FaClock />
                                                                        )}
                                                                    </td>
                                                                    <td
                                                                        style={{
                                                                            width: '28%'
                                                                        }}
                                                                    >
                                                                        {item.status ==
                                                                            'APPROVED' ||
                                                                        item.status ==
                                                                            'CANCEL'
                                                                            ? item.reason
                                                                            : ''}
                                                                    </td>
                                                                    <td
                                                                        style={{
                                                                            width: '25%'
                                                                        }}
                                                                    >
                                                                        {item.status ==
                                                                            'APPROVED' ||
                                                                        item.status ==
                                                                            'CANCEL'
                                                                            ? formatDMY(
                                                                                  String(
                                                                                      item.updatedOnFormatDateTime
                                                                                  )
                                                                              )
                                                                            : ''}
                                                                    </td>
                                                                </tr>
                                                                <tr
                                                                    key={
                                                                        '2-' +
                                                                        idx
                                                                    }
                                                                >
                                                                    <td></td>
                                                                    <td
                                                                        colSpan={
                                                                            5
                                                                        }
                                                                    >
                                                                        <table
                                                                            style={{
                                                                                width: '100%'
                                                                            }}
                                                                            className="table-borderless"
                                                                        >
                                                                            {!this
                                                                                .state
                                                                                .checkresponse
                                                                                ? this.state.formData[
                                                                                      'listApprovalDetail'
                                                                                  ][
                                                                                      idx
                                                                                  ][
                                                                                      'listEmployeeInPositionGroup'
                                                                                  ].map(
                                                                                      (
                                                                                          subitem: ModelSearchWorkflowDashboard.listEmployeeInPositionGroup,
                                                                                          subidx
                                                                                      ) => (
                                                                                          <tbody
                                                                                              key={
                                                                                                  subidx
                                                                                              }
                                                                                          >
                                                                                              <tr
                                                                                                  key={
                                                                                                      subidx
                                                                                                  }
                                                                                                  style={{
                                                                                                      width: '100%'
                                                                                                  }}
                                                                                              >
                                                                                                  <td
                                                                                                      style={{
                                                                                                          width: '20%'
                                                                                                      }}
                                                                                                  >
                                                                                                      {
                                                                                                          subitem.empid
                                                                                                      }
                                                                                                  </td>
                                                                                                  <td
                                                                                                      style={{
                                                                                                          width: '20%'
                                                                                                      }}
                                                                                                  >
                                                                                                      {
                                                                                                          subitem.empname
                                                                                                      }
                                                                                                  </td>
                                                                                                  <td
                                                                                                      className="text-center"
                                                                                                      style={{
                                                                                                          width: '10%'
                                                                                                      }}
                                                                                                  ></td>
                                                                                                  <td
                                                                                                      style={{
                                                                                                          width: '30%'
                                                                                                      }}
                                                                                                  ></td>
                                                                                                  <td
                                                                                                      style={{
                                                                                                          width: '25%'
                                                                                                      }}
                                                                                                  ></td>
                                                                                              </tr>
                                                                                          </tbody>
                                                                                      )
                                                                                  )
                                                                                : null}
                                                                        </table>
                                                                    </td>
                                                                </tr>
                                                            </tbody>
                                                        )
                                                    )}
                                            </Table>
                                        ) : (
                                            <WorkflowInboxInformationMobileTable
                                                checkresponse={
                                                    this.state.checkresponse
                                                }
                                                formData={this.state.formData}
                                            />
                                        )}
                                    </Col>
                                </Row>
                            </Loader>
                        </Container>
                    );
                }

                // Desktop goes here
                return (
                    <Container
                        id="content"
                        fluid={true}
                    >
                        <Loader loaded={isLoadWorkflowInboxValue}>
                            <Row className="mt-3">
                                <Col>
                                    <Card>
                                        <Card.Body>
                                            <table className="text-left">
                                                <tbody>
                                                    <tr>
                                                        <td
                                                            style={{
                                                                width: '50%',
                                                                verticalAlign:
                                                                    'top'
                                                            }}
                                                        >
                                                            <label>
                                                                <b>Subject :</b>
                                                            </label>
                                                        </td>
                                                        <td
                                                            style={{
                                                                width: '50%',
                                                                whiteSpace:
                                                                    'pre'
                                                            }}
                                                        >
                                                            {
                                                                this.state
                                                                    .formData[
                                                                    'subject'
                                                                ]
                                                            }
                                                        </td>
                                                    </tr>
                                                    <tr>
                                                        <td
                                                            style={{
                                                                width: '50%'
                                                            }}
                                                        >
                                                            <label>
                                                                <b>
                                                                    Document
                                                                    Type :
                                                                </b>
                                                            </label>
                                                        </td>
                                                        <td
                                                            style={{
                                                                width: '50%'
                                                            }}
                                                        >
                                                            {
                                                                this.state
                                                                    .formData[
                                                                    'docType'
                                                                ]
                                                            }
                                                        </td>
                                                    </tr>
                                                    <tr>
                                                        <td
                                                            style={{
                                                                width: '50%'
                                                            }}
                                                        >
                                                            <label>
                                                                <b>
                                                                    Document No.
                                                                    :
                                                                </b>
                                                            </label>
                                                        </td>
                                                        <td
                                                            style={{
                                                                width: '50%'
                                                            }}
                                                        >
                                                            {
                                                                this.state
                                                                    .formData[
                                                                    'docNo'
                                                                ]
                                                            }
                                                        </td>
                                                    </tr>
                                                    {this.state.formData[
                                                        'projectName'
                                                    ] && (
                                                        <tr>
                                                            <td
                                                                style={{
                                                                    width: '50%'
                                                                }}
                                                            >
                                                                <label>
                                                                    <b>
                                                                        Project
                                                                        Name :
                                                                    </b>
                                                                </label>
                                                            </td>
                                                            <td
                                                                style={{
                                                                    width: '50%'
                                                                }}
                                                            >
                                                                {
                                                                    this.state
                                                                        .formData[
                                                                        'projectName'
                                                                    ]
                                                                }
                                                            </td>
                                                        </tr>
                                                    )}
                                                    {this.state.formData[
                                                        'jobName'
                                                    ] && (
                                                        <tr>
                                                            <td
                                                                style={{
                                                                    width: '50%'
                                                                }}
                                                            >
                                                                <label>
                                                                    <b>
                                                                        Job Name
                                                                        :
                                                                    </b>
                                                                </label>
                                                            </td>
                                                            <td
                                                                style={{
                                                                    width: '50%'
                                                                }}
                                                            >
                                                                {
                                                                    this.state
                                                                        .formData[
                                                                        'jobName'
                                                                    ]
                                                                }
                                                            </td>
                                                        </tr>
                                                    )}
                                                    <tr>
                                                        <td
                                                            style={{
                                                                width: '50%'
                                                            }}
                                                        >
                                                            <label>
                                                                <b>Year :</b>
                                                            </label>
                                                        </td>
                                                        <td
                                                            style={{
                                                                width: '50%'
                                                            }}
                                                        >
                                                            {
                                                                this.state
                                                                    .formData[
                                                                    'docYear'
                                                                ]
                                                            }
                                                        </td>
                                                    </tr>
                                                    <tr>
                                                        <td
                                                            style={{
                                                                width: '50%'
                                                            }}
                                                        >
                                                            <label>
                                                                <b>
                                                                    Document
                                                                    Date :
                                                                </b>
                                                            </label>
                                                        </td>
                                                        <td
                                                            style={{
                                                                width: '50%'
                                                            }}
                                                        >
                                                            {!this.state
                                                                .checkresponse
                                                                ? formatYMDToDMY(
                                                                      String(
                                                                          this
                                                                              .state
                                                                              .formData[
                                                                              'docDate'
                                                                          ]
                                                                      )
                                                                  )
                                                                : ''}
                                                        </td>
                                                    </tr>
                                                    <tr>
                                                        <td
                                                            style={{
                                                                width: '50%'
                                                            }}
                                                        >
                                                            <label>
                                                                <b>
                                                                    Requestor :
                                                                </b>
                                                            </label>
                                                        </td>
                                                        <td
                                                            style={{
                                                                width: '50%'
                                                            }}
                                                        >
                                                            {
                                                                this.state
                                                                    .formData[
                                                                    'requestorName'
                                                                ]
                                                            }
                                                        </td>
                                                    </tr>
                                                    <tr>
                                                        <td
                                                            style={{
                                                                width: '50%'
                                                            }}
                                                        >
                                                            <label>
                                                                <b>
                                                                    Requestor
                                                                    Amount :
                                                                </b>
                                                            </label>
                                                        </td>
                                                        <td
                                                            style={{
                                                                width: '50%'
                                                            }}
                                                        >
                                                            {numberWithCommas(
                                                                this.state
                                                                    .formData[
                                                                    'docAmount'
                                                                ]
                                                            )}
                                                            &nbsp;
                                                            {
                                                                this.state
                                                                    .formData[
                                                                    'docCurrency'
                                                                ]
                                                            }
                                                        </td>
                                                    </tr>
                                                    <tr>
                                                        <td
                                                            style={{
                                                                width: '50%'
                                                            }}
                                                        >
                                                            <label>
                                                                <b>
                                                                    Current
                                                                    approval :
                                                                </b>
                                                            </label>
                                                        </td>
                                                        <td
                                                            style={{
                                                                width: '50%'
                                                            }}
                                                        >
                                                            {this.state
                                                                .formData[
                                                                'curtaskid'
                                                            ] +
                                                                '/' +
                                                                this.state
                                                                    .formData[
                                                                    'maxtaskid'
                                                                ]}
                                                        </td>
                                                    </tr>
                                                    <tr>
                                                        <td
                                                            style={{
                                                                width: '50%',
                                                                verticalAlign:
                                                                    'top'
                                                            }}
                                                        >
                                                            <label>
                                                                <b>
                                                                    Message from
                                                                    requested :
                                                                </b>
                                                            </label>
                                                        </td>
                                                        <td
                                                            style={{
                                                                width: '50%',
                                                                whiteSpace:
                                                                    'pre'
                                                            }}
                                                        >
                                                            {
                                                                this.state
                                                                    .formData[
                                                                    'requestorMessage'
                                                                ]
                                                            }
                                                        </td>
                                                    </tr>
                                                    {/* <tr>
                                                        <td style={{ width: '40%', verticalAlign: 'top' }}><label><b>Document Online Form :</b></label></td>
                                                        <td style={{ width: '60%' }}>
                                                            <table>
                                                                <tbody>
                                                                    {(this.state.formData['listSignFileUrl'] === "") && (this.state.formData['listFileUrl']) ? JSON.parse(this.state.formData['listFileUrl']).map((item: ModelSearchWorkflowDashboard.FileUrl) =>
                                                                    ((item.file_name != undefined || item.url != undefined) &&
                                                                        <tr key={item.file_name}>
                                                                            <td>
                                                                                <a href="javascript:void(0);" data-filename={item.file_name} data-filepath={item.url} onClick={(e) => this.onClickFileShowFile(e, item.file_name, item.url, 's3')}><FaShare style={{ transform: 'rotate(270deg)', color: 'green' }} /></a>&nbsp;
                                                                            </td>
                                                                            <td>
                                                                                <a href="javascript:void(0);" data-filename={item.file_name} data-filepath={item.url} onClick={(e) => this.onClickFileShowIframe(e, item.file_name, 's3')}><FaFileAlt />&nbsp;{item.file_name}</a>
                                                                            </td>
                                                                        </tr>
                                                                    )
                                                                    ) : (this.state.formData['listSignFileUrl']) ? JSON.parse(this.state.formData['listSignFileUrl']).map((item: ModelSearchWorkflowDashboard.FileUrl) =>
                                                                    ((item.file_name != undefined || item.url != undefined) &&
                                                                        <tr key={item.file_name}>
                                                                            <td>
                                                                                <a href="javascript:void(0);" data-filename={item.file_name} data-filepath={item.url} onClick={(e) => this.onClickFileShowFile(e, item.file_name, item.url, 's3')}><FaShare style={{ transform: 'rotate(270deg)', color: 'green' }} /></a>&nbsp;
                                                                            </td>
                                                                            <td>
                                                                                <a href="javascript:void(0);" data-filename={item.file_name} data-filepath={item.url} onClick={(e) => this.onClickFileShowIframe(e, item.file_name, 's3')}><FaFileAlt />&nbsp;{item.file_name}</a>
                                                                            </td>
                                                                        </tr>
                                                                    )) : null}
                                                                </tbody>
                                                            </table>
                                                        </td>
                                                    </tr> */}
                                                    <tr>
                                                        <td
                                                            style={{
                                                                width: '40%'
                                                            }}
                                                        >
                                                            <label>
                                                                <b>
                                                                    Document
                                                                    Attachment :
                                                                </b>
                                                            </label>
                                                        </td>
                                                        <td
                                                            style={{
                                                                width: '60%'
                                                            }}
                                                        >
                                                            <table>
                                                                <tbody>
                                                                    {this.state
                                                                        .formData[
                                                                        'listDMS'
                                                                    ] &&
                                                                        JSON.parse(
                                                                            this
                                                                                .state
                                                                                .formData[
                                                                                'listDMS'
                                                                            ]
                                                                        ).map(
                                                                            (
                                                                                item: ModelSearchWorkflowDashboard.File
                                                                            ) =>
                                                                                item &&
                                                                                (item.file_name ||
                                                                                    item.file_path) && (
                                                                                    <tr
                                                                                        key={
                                                                                            item.file_name
                                                                                        }
                                                                                    >
                                                                                        <td>
                                                                                            <a
                                                                                                href="javascript:void(0);"
                                                                                                data-filename={
                                                                                                    item.file_name
                                                                                                }
                                                                                                data-filepath={
                                                                                                    item.file_path
                                                                                                }
                                                                                                onClick={e =>
                                                                                                    this.onClickFileShowFile(
                                                                                                        e,
                                                                                                        item.file_name,
                                                                                                        item.file_path,
                                                                                                        undefined
                                                                                                    )
                                                                                                }
                                                                                            >
                                                                                                <FaShare
                                                                                                    style={{
                                                                                                        transform:
                                                                                                            'rotate(270deg)',
                                                                                                        color: 'green'
                                                                                                    }}
                                                                                                />
                                                                                            </a>
                                                                                            &nbsp;
                                                                                        </td>
                                                                                        <td>
                                                                                            <a
                                                                                                href="javascript:void(0);"
                                                                                                data-filename={
                                                                                                    item.file_name
                                                                                                }
                                                                                                data-filepath={
                                                                                                    item.file_path
                                                                                                }
                                                                                                onClick={e =>
                                                                                                    this.onClickFileShowIframe(
                                                                                                        e,
                                                                                                        item.file_name,
                                                                                                        undefined
                                                                                                    )
                                                                                                }
                                                                                            >
                                                                                                <FaFileAlt />
                                                                                                &nbsp;
                                                                                                {
                                                                                                    item.file_name
                                                                                                }
                                                                                            </a>
                                                                                        </td>
                                                                                    </tr>
                                                                                )
                                                                        )}
                                                                </tbody>
                                                            </table>
                                                        </td>
                                                    </tr>
                                                </tbody>
                                            </table>
                                        </Card.Body>
                                    </Card>
                                </Col>
                            </Row>
                            {this.state.src != undefined && (
                                <Row className="mt-3">
                                    <Col>
                                        <Card>
                                            <Card.Header>
                                                {this.state.fileName}
                                                <span
                                                    style={{ float: 'right' }}
                                                >
                                                    <Button
                                                        size="sm"
                                                        variant="outline-danger"
                                                        onClick={
                                                            this.handleCloseFile
                                                        }
                                                    >
                                                        <FaTimes
                                                            size={15}
                                                            color="red"
                                                        />
                                                        &nbsp;ปิด
                                                    </Button>
                                                </span>
                                            </Card.Header>
                                            <Card.Body>
                                                <iframe
                                                    style={{
                                                        minHeight: '1025px'
                                                    }}
                                                    src={this.state.src}
                                                    height="100%"
                                                    width="100%"
                                                    frameBorder="0"
                                                />
                                            </Card.Body>
                                        </Card>
                                    </Col>
                                </Row>
                            )}
                            <Row className="mt-3">
                                <Col>
                                    <Table
                                        responsive
                                        hover
                                        size="lg"
                                        className="table-bordered"
                                    >
                                        <thead>
                                            <tr
                                                className="table-active text-center"
                                                style={{ width: '100%' }}
                                            >
                                                <th
                                                    style={{ width: '2%' }}
                                                ></th>
                                                <th style={{ width: '20%' }}>
                                                    Approver Code
                                                </th>
                                                <th style={{ width: '20%' }}>
                                                    Approver Name
                                                </th>
                                                <th style={{ width: '10%' }}>
                                                    สถานะ
                                                </th>
                                                <th style={{ width: '28%' }}>
                                                    Description
                                                </th>
                                                <th style={{ width: '25%' }}>
                                                    Approve Date/Time
                                                </th>
                                            </tr>
                                        </thead>

                                        {!this.state.checkresponse
                                            ? this.state.formData[
                                                  'listApprovalDetail'
                                              ].map(
                                                  (
                                                      item: ModelSearchWorkflowDashboard.WorkflowInboxValue,
                                                      idx
                                                  ) => (
                                                      <tbody key={idx}>
                                                          <tr
                                                              key={'1-' + idx}
                                                              style={
                                                                  item.status ==
                                                                  'APPROVED'
                                                                      ? {
                                                                            backgroundColor:
                                                                                '#90be6d',
                                                                            width: '100%'
                                                                        }
                                                                      : item.status ==
                                                                        'CANCEL'
                                                                      ? {
                                                                            backgroundColor:
                                                                                '#e5383b',
                                                                            width: '100%'
                                                                        }
                                                                      : {
                                                                            backgroundColor:
                                                                                '#f8f9fa',
                                                                            width: '100%'
                                                                        }
                                                              }
                                                          >
                                                              <td
                                                                  style={{
                                                                      width: '2%'
                                                                  }}
                                                              >
                                                                  {idx + 1}
                                                              </td>
                                                              <td
                                                                  style={{
                                                                      width: '20%'
                                                                  }}
                                                              >
                                                                  {
                                                                      item.positiongroupname
                                                                  }
                                                              </td>
                                                              <td
                                                                  style={{
                                                                      width: '20%'
                                                                  }}
                                                              >
                                                                  {item.status ==
                                                                      'APPROVED' ||
                                                                  item.status ==
                                                                      'CANCEL'
                                                                      ? item.updated_byname
                                                                      : ''}
                                                              </td>
                                                              <td
                                                                  style={{
                                                                      width: '10%'
                                                                  }}
                                                                  className="text-center"
                                                              >
                                                                  {item.status ==
                                                                  'APPROVED' ? (
                                                                      <FaCheck />
                                                                  ) : item.status ==
                                                                    'CANCEL' ? (
                                                                      <FaTimes />
                                                                  ) : (
                                                                      <FaClock />
                                                                  )}
                                                              </td>
                                                              <td
                                                                  style={{
                                                                      width: '28%'
                                                                  }}
                                                              >
                                                                  {item.status ==
                                                                      'APPROVED' ||
                                                                  item.status ==
                                                                      'CANCEL'
                                                                      ? item.reason
                                                                      : ''}
                                                              </td>
                                                              <td
                                                                  style={{
                                                                      width: '25%'
                                                                  }}
                                                              >
                                                                  {item.status ==
                                                                      'APPROVED' ||
                                                                  item.status ==
                                                                      'CANCEL'
                                                                      ? formatDMY(
                                                                            String(
                                                                                item.updatedOnFormatDateTime
                                                                            )
                                                                        )
                                                                      : ''}
                                                              </td>
                                                          </tr>
                                                          <tr key={'2-' + idx}>
                                                              <td></td>
                                                              <td colSpan={5}>
                                                                  <table
                                                                      style={{
                                                                          width: '100%'
                                                                      }}
                                                                      className="table-borderless"
                                                                  >
                                                                      <tbody>
                                                                          {!this
                                                                              .state
                                                                              .checkresponse
                                                                              ? this.state.formData[
                                                                                    'listApprovalDetail'
                                                                                ][
                                                                                    idx
                                                                                ][
                                                                                    'listEmployeeInPositionGroup'
                                                                                ].map(
                                                                                    (
                                                                                        subitem: ModelSearchWorkflowDashboard.listEmployeeInPositionGroup,
                                                                                        subidx
                                                                                    ) => (
                                                                                        <tr
                                                                                            key={
                                                                                                idx +
                                                                                                '-' +
                                                                                                subidx
                                                                                            }
                                                                                            style={{
                                                                                                width: '100%'
                                                                                            }}
                                                                                        >
                                                                                            <td
                                                                                                style={{
                                                                                                    width: '20%'
                                                                                                }}
                                                                                            >
                                                                                                {
                                                                                                    subitem.empid
                                                                                                }
                                                                                            </td>
                                                                                            <td
                                                                                                style={{
                                                                                                    width: '20%'
                                                                                                }}
                                                                                            >
                                                                                                {
                                                                                                    subitem.empname
                                                                                                }
                                                                                            </td>
                                                                                            <td
                                                                                                className="text-center"
                                                                                                style={{
                                                                                                    width: '10%'
                                                                                                }}
                                                                                            ></td>
                                                                                            <td
                                                                                                style={{
                                                                                                    width: '30%'
                                                                                                }}
                                                                                            ></td>
                                                                                            <td
                                                                                                style={{
                                                                                                    width: '25%'
                                                                                                }}
                                                                                            ></td>
                                                                                        </tr>
                                                                                    )
                                                                                )
                                                                              : null}
                                                                      </tbody>
                                                                  </table>
                                                              </td>
                                                          </tr>
                                                      </tbody>
                                                  )
                                              )
                                            : null}
                                    </Table>
                                </Col>
                            </Row>

                            {/*<Modal isOpen={this.state.messageModal} toggle={() => this.handleMessage()} size="lg" backdrop="static" keyboard={false}>*/}
                            {/*    <ModalHeader toggle={() => this.handleMessage()}>Message</ModalHeader>*/}
                            {/*    <ModalBody>*/}
                            {/*        <Row>*/}
                            {/*            <Col>*/}
                            {/*                <Form.Group controlId="exampleForm.ControlTextarea1">*/}
                            {/*                    <Form.Control as="textarea" rows={5} onChange={this.onchangeMessage} />*/}
                            {/*                </Form.Group>*/}
                            {/*            </Col>*/}
                            {/*        </Row>*/}
                            {/*    </ModalBody>*/}
                            {/*    <ModalFooter>*/}
                            {/*        */}
                            {/*  <Button color="primary" onClick={() => this.handleClose(this.state.wfId, "", "", "")}>Save</Button>*/}
                            {/*        <Button variant="info" onClick={() => this.onsuccessApproveReject(workflowValueUse.wfInstanceNo, workflowValueUse.userId, this.state.formData['wfType'])}>Confirm</Button>*/}
                            {/*        <Button variant="secondary" onClick={() => this.handleMessage()}>Cancel</Button>*/}
                            {/*    </ModalFooter>*/}
                            {/*</Modal>*/}
                            {/*success modal*/}
                            {/*<Modal isOpen={this.state.successModal} toggle={() => this.handleSuccessModal()} size="lg" backdrop="static" keyboard={false}>*/}
                            {/*    <ModalHeader toggle={() => this.handleSuccessModal()}>{this.state.successModalHeader}</ModalHeader>*/}
                            {/*    <ModalBody scrollable>*/}
                            {/*        <Row>*/}
                            {/*            <Col>*/}
                            {/*                {this.props.isLoadApproveReject && this.props.statusResponseApproveReject == 200 && this.props.responseApproveReject.length != 0 && this.props.responseApproveReject['result_list'].map((item: ModelApproveRejectWorkflowInformation.ApproveRejectWorkflowInformationResponse, idx) => {*/}
                            {/*                    console.log(item)*/}
                            {/*                    return (*/}
                            {/*                        <Card className="shadow-sm p-1 mb-1 bg-white rounded">*/}
                            {/*                            <CardBody>*/}
                            {/*                                <Row>*/}
                            {/*                                    <Col lg={2}>*/}
                            {/*                                        <div style={{ alignContent: 'center', verticalAlign: 'middle', textAlign: 'center' }}>*/}
                            {/*                                            {item.status == "SUCCESS" ? <React.Fragment><FaCheckCircle size={50} color="green" /></React.Fragment> : <React.Fragment><FaTimesCircle size={80} color="red" style={{ paddingTop: '30px' }} /></React.Fragment>}*/}
                            {/*                                        </div>*/}
                            {/*                                    </Col>*/}
                            {/*                                    <Col lg={10}>*/}
                            {/*                                        <Table className="table table-borderless table-hover" size="sm">*/}
                            {/*                                            <tr className="require-field" style={{ width: '100%' }}>*/}
                            {/*                                                <td className="font-weight-bold" style={{ width: '30%' }}>Document NO. :</td>*/}
                            {/*                                                <td style={{ width: '70%' }}>{item.docNo}</td>*/}
                            {/*                                            </tr>*/}
                            {/*                                            <tr className="require-field" style={{ width: '100%' }}>*/}
                            {/*                                                <td className="font-weight-bold" style={{ width: '30%' }}>Status :</td>*/}
                            {/*                                                <td style={{ width: '70%' }}>{item.status}</td>*/}
                            {/*                                            </tr>*/}
                            {/*                                            <tr className="require-field" style={{ width: '100%' }}>*/}
                            {/*                                                <td className="font-weight-bold" style={{ width: '30%' }}>Message :</td>*/}
                            {/*                                                <td style={{ width: '70%' }}>{item.message}</td>*/}
                            {/*                                            </tr>*/}
                            {/*                                            {item.status != 'SUCCESS' ?*/}
                            {/*                                                <tr className="require-field" style={{ width: '100%' }}>*/}
                            {/*                                                    <td className="font-weight-bold" style={{ width: '30%' }}>Error Message :</td>*/}
                            {/*                                                    <td style={{ width: '70%' }}>{item.errorMessage}</td>*/}
                            {/*                                                </tr>*/}
                            {/*                                                : ''}*/}
                            {/*                                        </Table>*/}
                            {/*                                    </Col>*/}
                            {/*                                </Row>*/}
                            {/*                            </CardBody>*/}
                            {/*                        </Card>*/}
                            {/*                    )*/}
                            {/*                })}*/}
                            {/*            </Col>*/}
                            {/*        </Row>*/}
                            {/*    </ModalBody>*/}
                            {/*    <ModalFooter>*/}
                            {/*        <Button variant="danger" onClick={() => this.handleSuccessModal()}>Close</Button>*/}
                            {/*    </ModalFooter>*/}
                            {/*</Modal>*/}
                        </Loader>
                    </Container>
                );
            }
        }
    }
}
export default connect(
    (state: ApplicationState) => ({
        ...state.searchworkflowDashboard,
        ...state.approveRejectWorkflowInformation
    }),
    {
        ...SearchWorkflowDashboardStore.actionCreators,
        ...ApproveRejectWorkflowInformationStore.actionCreators
    }
)(WorkflowInboxInformationDetail);
