import React, { useEffect } from 'react';
import * as XLSX from 'xlsx';
import useLoader from 'hooks/useLoader';
import { Modal, ModalHeader, ModalBody, ModalFooter, Table } from 'reactstrap';
import { Button } from 'react-bootstrap';

import { WorkflowInbox } from 'models/workflow/WorkflowInbox';
import { ResponseList } from 'models/common/respone.model';
import { MasterCompanyList } from 'models/company/MasterCompany';
import { FaPlusCircle } from 'react-icons/fa';

type Props = {
    isOpen?: boolean;
    toggle?: () => void;
    workflowDocumentDetail: WorkflowInbox[];
    companyMaster: MasterCompanyList[];
};

function AssignmentRoutingConfigDocModal(props: Props) {
    const { isOpen, toggle, workflowDocumentDetail, companyMaster } = props;

    const loading = useLoader();
    const mappingCompany = new Map<string, string>(
        companyMaster?.map(value => [value.sid, value.company_name])
    );

    const ExportTemplate = async () => {
        try {
            loading.show();
            var date = new Date();
            var filename = 'AssignmentRoutingDocument ' + date.getTime().toString() + '.xlsx';
            var Employee: any = [];

            if (workflowDocumentDetail) {
                workflowDocumentDetail.map(item => {
                    let data = {};
                    data['Company'] = mappingCompany.get(item.app_id);
                    data['Document Name'] = item.subject;
                    data['Document Number'] = item.docNo;
                    data['Document Year'] = item.docYear;
                    data['Document Date'] = item.docDate;
                    data['Created By'] = item.created_by;
                    data['Approver'] = item.staffname;
                    Employee.push(data);
                });
                var ws_Employee = XLSX.utils.json_to_sheet(Employee);
                var wb = XLSX.utils.book_new();
                XLSX.utils.book_append_sheet(wb, ws_Employee, 'AssignmentRoutingDocument');
                XLSX.writeFile(wb, filename);
            }
        } finally {
            loading.hide();
        }
    };

    return (
        <Modal
            isOpen={isOpen}
            toggle={toggle}
            className="h-[90%] w-[98%] max-w-[1920px]"
        >
            <ModalHeader>
                <p className="text-base font-semibold">Document Workflow</p>
            </ModalHeader>
            <ModalBody>
                <span className='align-middle'>เอกสารคงค้าง: {workflowDocumentDetail && workflowDocumentDetail.length} เอกสาร</span>
                <Button
                    variant="outline-success"
                    size="sm"
                    className='ml-3'
                    onClick={ExportTemplate}
                >
                    <FaPlusCircle />
                    &nbsp;Export Excel
                </Button>
                <div style={{ overflowX: 'scroll' }} className='mt-2'>
                    <Table className="table-bordered table-hover">
                        <thead>
                            <tr
                                className="table-active thead-light text-nowrap text-center"
                                style={{ width: '100%' }}
                            >
                                <th>No.</th>
                                <th>Company</th>
                                <th>Document Name</th>
                                <th>Document Number</th>
                                <th>Document Year</th>
                                <th>Document Type</th>
                                <th>Document Date</th>
                                <th>Created By</th>
                                <th>Approver</th>
                            </tr>
                        </thead>
                        <tbody>
                            {workflowDocumentDetail && workflowDocumentDetail.map((document, index) => (
                                <tr className='font-light' key={index}>
                                    <td className='text-center'>{index + 1}</td>
                                    <td>{mappingCompany.get(document.app_id)}</td>
                                    <td>{document.subject}</td>
                                    <td>{document.docNo}</td>
                                    <td className='text-center'>{document.docYear}</td>
                                    <td>{document.docType}</td>
                                    <td className='text-center'>{document.docDate}</td>
                                    <td>{document.created_by}</td>
                                    <td className='text-center'>{document.staffid}</td>
                                </tr>
                            ))}
                        </tbody>
                    </Table>
                </div >
            </ModalBody>
            <ModalFooter>
                <Button
                    onClick={toggle}
                    variant="outline-danger"
                    size="sm"
                >
                    Close
                </Button>
            </ModalFooter>
        </Modal >
    )
}

export default AssignmentRoutingConfigDocModal;