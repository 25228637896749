import * as React from "react";
import { connect } from "react-redux";
import { Button, Container, Row, Col, Form, Accordion, Table, Spinner, Image } from "react-bootstrap";
import { FaPlusCircle, FaSearch } from "react-icons/fa";
import { Link } from "react-router-dom";
import PositionEmployeeTable from "./PositionEmployeeTable";
import WorkflowConfigSearchTap from "./WorkflowConfigSearchTap";
import * as XLSX from 'xlsx';

import * as AppState from '../../store/AppState'
import * as ModelsAppState from '../../models/AppState'
import { ApplicationState } from "../../store";
import axios from "axios";
import { MasterCompanyResponse } from "models/company/MasterCompany";
type AppStateProps = ModelsAppState.AppState & typeof AppState.actionCreators

class PositionEmployee extends React.Component<any, { roleid: string, empid: string, reset: boolean, resetSearchTap: boolean }, AppStateProps> {

    constructor(props: any) {
        super(props);
        this.state = {
            roleid: "",
            empid: "",
            reset: false,
            resetSearchTap: false
        }
    }

    componentDidMount() {
        // document.getElementsByClassName('Loader__background')[0].className = "Loader__background show";
        this.props.updateUrl(this.props.location.pathname)
    }

    onsubmit = () => {
        console.log("submit");
        document.getElementsByClassName('Loader__background')[0].className = "Loader__background show";
        // return <Redirect to="/addpositionemployee" />;
    };

    callbackReset = () => {
        this.setState({
            reset: false
        })
    }

    handleReset = (value: boolean) => {
        this.setState({
            reset: value
        })
    }

    handleSearch = (role_id: any, emp_id: any, authority_group: any, workflowid: any) => {
        //console.log(role_id + " - " + emp_id)
        this.setState({
            roleid: role_id,
            empid: emp_id
        })
        setTimeout(() => {
            this.handleReset(true);
        }, 300);

    }

    callbackResetSearch = () => {
        this.setState({
            resetSearchTap: true
        })
    }

    callbackResetSearchTap = () => {
        this.setState({
            resetSearchTap: false
        })
    }

    ExportTemplate = async () => {
        var date = new Date();
        var filename = 'Position Role MasterEmployee' + date.getTime().toString() + '.xlsx';

        const response = await axios.get<MasterCompanyResponse>(
            '/v1/company_master',
            {
                headers: {
                    Authorization:
                        'Bearer ' + localStorage.getItem('WF_APPLICATION')
                }
            }
        );
        const mappingCompany = new Map<string, string>(
            response.data.result_list.map(value => [value.sid, value.company_name])
        );

        fetch(`/v1/configuration/position-role-employee?sid=${this.props.company}&role_id=${this.state.roleid}&emp_id=${this.state.empid}`,
            {
                headers: {
                    'Content-Type': 'application/json',
                    'authorization': 'Bearer ' + localStorage.getItem('WF_APPLICATION')
                }
            }
        )
            .then(res => res.json())
            .then(
                (result) => {
                    var Employee: any = []
                    result.map((item) => {
                        let data = {}
                        data["company"] = mappingCompany.get(item.sid);
                        data["positioncode"] = item.positioncode;
                        data["positionname"] = item.positionname;
                        data["empid"] = item.empid;
                        data["validfrom"] = item.validfrom;
                        data["validto"] = item.validto;
                        data["created_by"] = item.created_by;
                        data["created_on"] = item.created_on;
                        Employee.push(data);
                    })
                    var ws_Employee = XLSX.utils.json_to_sheet(Employee);
                    var wb = XLSX.utils.book_new();
                    XLSX.utils.book_append_sheet(wb, ws_Employee, "PositionRole MasterEmployee");
                    XLSX.writeFile(wb, filename);
                    document.getElementsByClassName('Loader__background')[0].className = "Loader__background hide";
                },
                (error) => {
                    document.getElementsByClassName('Loader__background')[0].className = "Loader__background hide";
                }
            )
    }

    render() {

        console.log(this.props.company)
        return (
            <div>
                <div className="master-data-content">
                    <div className='trapezoid-head' style={{ width: '200px' }}>
                        <span>
                            Role Employee Criteria
                        </span>
                    </div>
                </div>
                <Row>
                    <Col>

                        <div className="mat-box">
                            <WorkflowConfigSearchTap handleSearch={this.handleSearch} callbackResetSearchTap={this.callbackResetSearchTap.bind(this)} keyResetSearchTap={this.state.resetSearchTap} />
                            <Row>
                                <Col>
                                    <div className='trapezoid trapezoid-purple' style={{ width: '170px', display: 'inline-block' }}>
                                        <span>
                                            <i className="fa fa-search" aria-hidden="true"></i>
                                            Role Employee
                                        </span>
                                    </div>
                                    <div style={{ display: 'inline-block', float: 'right' }}> <Link to="/addpositionemployee">
                                        <Button variant="outline-info" size="sm">
                                            <FaPlusCircle />
                                            &nbsp;Add Role Employee
                                        </Button>
                                    </Link></div>
                                    <div style={{ display: 'inline-block', float: 'right', marginRight: 10 }}>
                                        <Button variant="outline-success" size="sm" onClick={this.ExportTemplate}>
                                            <FaPlusCircle />
                                            &nbsp;Export Excel
                                        </Button>
                                    </div>
                                    <PositionEmployeeTable callbackReset={this.callbackReset.bind(this)} callbackResetSearch={this.callbackResetSearch.bind(this)} keyReset={this.state.reset} roleid={this.state.roleid} empid={this.state.empid} />
                                </Col>
                            </Row>
                        </div>
                    </Col>
                </Row>
                {/*<div className="mat-box">
                    <Row>
                        <Col>
                            <div className='trapezoid trapezoid-purple' style={{ width: '230px', display: 'inline-block' }}>
                                    <span>
                                    <i className="fa fa-search" aria-hidden="true"></i>
                                    Role Employee
                                    </span>
                            </div>
                            <div style={{display:'inline-block',float:'right'}}> <Link to="/addpositionemployee">
                                <Button variant="info" size="sm">
                                    <FaPlusCircle />
                                                    &nbsp;Add Role Employee
                                            </Button>
                            </Link></div>
                            <PositionEmployeeTable />
                        </Col>
                    </Row>
                </div>*/}
                {/*<Row>
                    <Col>
                        <Card className="shadow-lg p-3 mb-5 bg-white rounded">
                            <Card.Header>
                                <Row>
                                    <Col><h4>Role Employee Criteria</h4></Col>
                                    <Col className="text-right">
                                        <Link to="/addpositionemployee">
                                            <Button variant="info" size="sm">
                                                <FaPlusCircle />
                                                    &nbsp;Add Role Employee
                                            </Button>
                                        </Link>
                                    </Col>
                                </Row>
                            </Card.Header>
                            <Card.Body>
                                <div className="mt-2">
                                    <PositionEmployeeTable />
                                </div>
                            </Card.Body>
                        </Card>
                    </Col>
                </Row>*/}
            </div >
        );
    }
}
export default connect((state: ApplicationState) => ({ ...state.appState }), ({ ...AppState.actionCreators }))(PositionEmployee);
