import {
    LogCallBackInfo,
    ObjCallbackLog
} from 'data/CallbackLog/schemas/CallbackLog.schema';
import { ResponseList } from 'models/Response';
import { formatYMDToDMY } from 'modules/Common';
import React from 'react';
import { Col, Container, Row, Spinner, Table } from 'react-bootstrap';
import { FaSearch } from 'react-icons/fa';
import { TbRefresh } from 'react-icons/tb';

type Props = {
    workflowLogResponse: ResponseList<LogCallBackInfo>;
    mappingCompany: Map<string, string>;
    refreshLog: (id: number, instanceNo: string) => void;
    explorerDetail: (logDetail?: LogCallBackInfo) => void;
};

function CallbackLogTable(props: Props) {
    const { workflowLogResponse, mappingCompany, refreshLog, explorerDetail } =
        props;

    const errorMessage = `{"Message":"ERROR F1CallBackV2 = Server was unable to process request. ---> ไม่สามารถ Release เอกสารได้เนื่องจากสถานะเอกสารไม่ใช่ Not Release\n"}{"Message":"ERROR F1CallBackV2 = ไม่สามารถยกเลิกได้เนื่องจาก : ไม่สามารถ Release รายการได้ เนื่องจากเอกสารมีสถานะไม่ถูกต้อง !!"}`;

    return (
        <Container>
            <Row className="mt-3">
                <Col>
                    <Table
                        className="table-bordered align-middle"
                        size="sm"
                    >
                        <thead>
                            <tr
                                style={{ width: '100%' }}
                                className="table-active thead-light text-center"
                            >
                                <th style={{ width: '3%' }}></th>
                                <th style={{ width: '5%' }}>Callback ID</th>
                                <th style={{ width: '8%' }}>Callback Status</th>
                                <th style={{ width: '13%' }}>Company</th>
                                <th style={{ width: '9%' }}>Document Number</th>
                                <th style={{ width: '8%' }}>Document Year</th>
                                <th style={{ width: '15%' }}>
                                    Callback Message
                                </th>
                                <th style={{ width: '6%' }}>Callback On</th>
                                <th style={{ width: '5%' }}>See More</th>
                            </tr>
                        </thead>
                        <tbody>
                            {!workflowLogResponse ? (
                                <tr>
                                    <td
                                        colSpan={9}
                                        style={{
                                            background:
                                                'rgba(209, 250, 221, 1)',
                                            color: 'black',
                                            fontSize: '10pt',
                                            textAlign: 'center'
                                        }}
                                    >
                                        &nbsp;&nbsp;No Data...
                                    </td>
                                </tr>
                            ) : (
                                    workflowLogResponse.result_list &&
                                    workflowLogResponse.result_list.map(
                                    (value, index) => (
                                        <tr key={index}>
                                            <td>
                                                <div
                                                    className={`text-center ${value.callbackstatus !==
                                                        'E' || value.callback_errmessage === errorMessage
                                                        ? 'text-gray-400 pointer-events-none'
                                                        : 'text-blue-500'
                                                        }`}
                                                    onClick={() => {
                                                        refreshLog(value.id, value.instanceno);
                                                    }}
                                                    aria-disabled="true"
                                                >
                                                    <TbRefresh />
                                                </div>
                                            </td>
                                            <td className="text-center">
                                                {value.callbackId}
                                            </td>
                                            <td className="text-center">
                                                {value.callbackstatus}
                                            </td>
                                            <td className="text-center">
                                                {mappingCompany.get(value.sid)}
                                            </td>
                                            <td className="text-center">
                                                {value.paramdocno}
                                            </td>
                                            <td className="text-center">
                                                {
                                                    JSON.parse(
                                                        value.objCallbackContentGroup
                                                    ).wfCallbackContents[0]
                                                        .parameter.DOC_YEAR
                                                }
                                            </td>
                                            <td className="text-center">
                                                {value.callback_errmessage}
                                            </td>
                                            <td className="text-center">
                                                {formatYMDToDMY(
                                                    value.callback_on
                                                )}
                                            </td>
                                            <td
                                                className="text-center"
                                                onClick={() => {
                                                    explorerDetail(value);
                                                }}
                                            >
                                                <FaSearch />
                                            </td>
                                        </tr>
                                    )
                                )
                            )}
                        </tbody>
                    </Table>
                </Col>
            </Row>
        </Container>
    );
}

export default CallbackLogTable;
